export enum routes {
  DASHBOARD = '/dashboard',
  TYPOGRAPHY = '/typography',
  TABLES = '/tables',
  NOTIFICATION = '/notification',
  UI_ELEMENTS_ICONS = '/ui/icons',
  UI_ELEMENTS_CHARTS = '/ui/charts',
  UI_ELEMENTS_MAP = '/ui/map',
  LOGIN = '/login',

  PRODUCTS = '/products',
  ADD_PRODUCT ='/products/add',
  CREATE_PRODUCT = '/products/create',
  ORDERS = '/orders',
  ORDER_DETAIL="/orders/detail",
  SUBSCRIPTION_ORDER_DETAIL="/subscription/orders/detail",
  SUPPLIER= '/suppliers',
  ADD_SUPPLIER ='/suppliers/add',

  BRANCHES= '/branches',
  ADD_BRANCHES="/branches/add",
  BRANCHES_DELIVERY_AREA="/branches/detail/delivery-area/",
  MEMBERS= '/members',
  ORDERSUPDATELOG ='/orderUpdate',
  MEMBERS_ADD="/members/add",
  CATEGORY='/categories',
  CATEGORIES_CREATE = "/categories/create/",
  SETTINGS='/settings',
  STAFF='/staff',
  ADD_STAFF ='/staff/add',
  TENANT='/tenant',
  ADD_TENANT ='/tenant/add',
  PROFILE='/profile',
  SUBSCRIPTION='/subscription',
  SUPPLIER_ORDER='/supplier-orders',
  SUPPLIER_INVOICE ='/supplier-orders/invoice',
  ORDER_TO_INOVICE = '/orders/invoice/',
  EVALUATIONS='/evaluations',
  STATEMENT='/evaluations/statement/',
  COUPONS='/coupons/',
  COUPONS_ADD='/coupons/add',
  QRCODES='/qrcode/',
  QRCODES_ADD='/qrcode/add',
  NOTIFICATIONS='/notification/',
  NOTIFICATIONS_ADD='/notification/add',
  DETAIL_CATEGORY_ARRANGE_PRODUCTS="categories/detail/arrange-products"
}
