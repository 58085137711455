import { Component } from '@angular/core';
import { routes } from '../../consts/routes';
import {Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public routes: typeof routes = routes;
  public isOpenUiElements = false;
  public location = [] ;
  appcmsUser: any;
  role:string;
  constructor(private  _router : Router, public translate: TranslateService) 
  {      
    const currentLanguage = this.translate.getBrowserLang();
    translate.setDefaultLang(currentLanguage);
    
    this.location = _router.url.split('/')
    // this.location = _router.url;
    // console.log(this.location[1])
  }

  ngOnInit(): void { 
    this.appcmsUser = window.localStorage.getItem('appcmsUser') ? JSON.parse(window.localStorage.getItem('appcmsUser')) : {};
    this.role = this.appcmsUser.userType.name;
  }

  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }
}
