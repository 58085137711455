<mat-toolbar class="header">

  <div class="header__title">
    <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon" (click)="openMenu()">menu</mat-icon>
    <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon"  (click)="openMenu()">menu</mat-icon> 

    <!-- <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
    <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">arrow_back</mat-icon> 
    </button> -->
    <h6 class="header__title-text"><img src="../../../../../assets/images/header-logo.png" height="35px"/></h6>
  </div>

  <div class="header-toolbar">
    <!-- <a
      mat-flat-button
      class="unlock-button"
      href="https://flatlogic.com/templates/angular-material-admin-full/demo"
      target="_blank"
    >Unlock Full Version</a>
    <app-search></app-search> -->
    <!-- <app-notifications></app-notifications>
    <app-email [emails]="emails$ | async"></app-email> -->
    <app-user [user]="user$ | async" (signOut)="signOut()"></app-user>
  </div>

</mat-toolbar>
