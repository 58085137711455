import { Component, OnInit, Inject } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { BranchService } from '../../services/branch.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { BranchDetailsFormControls, OrdersMessages, productMessage } from 'src/app/pages/constants';

@Component({
  selector: 'app-delivery-type-dialog',
  templateUrl: './delivery-type-dialog.component.html',
  styleUrls: ['./delivery-type-dialog.component.scss'],
})
export class DeliveryTypeDialogComponent implements OnInit {
  bundledForm: FormGroup;
  onDemandForm: FormGroup;
  id: number;
  Bundled: boolean;
  OnDemant: boolean;
  selectedTab: number;
  selectedTabindex: number;
  weekdays: boolean;
  deliveryType: string;
  formType: string;
  public formControl = BranchDetailsFormControls;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DeliveryTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private branchService: BranchService,
    private notifyService: NotificationService,
    private translate: TranslateService
  ) {
    this.id = this.data.deliverytypeid;
    if (this.id) {
      this.getDeliveryType(this.id);
    }

    this.deliveryType = 'both';
  }

  condition(field1: any = null, field2: any = null, field3: any = null) {
    // check if one field is required secound should be required
    if (
      !this.bundledForm.value[field1] &&
      !this.bundledForm.value[field2] &&
      !this.bundledForm.value[field3]
    ) {
      // if both null true
      this.bundledForm.get([field1]).setErrors(null);
      this.bundledForm.get([field2]).setErrors(null);
      this.bundledForm.get([field3]).setErrors(null);
      this.weekdays = false;
    } else if (
      this.bundledForm.value[field1] &&
      this.bundledForm.value[field2] &&
      this.bundledForm.value[field3]
    ) {
      // if both not null true
      this.bundledForm.get([field1]).setErrors(null);
      this.bundledForm.get([field2]).setErrors(null);
      this.bundledForm.get([field3]).setErrors(null);
      this.weekdays = false;
    } else if (
      this.bundledForm.value[field1] &&
      !this.bundledForm.value[field2] &&
      !this.bundledForm.value[field3]
    ) {
      // if first
      this.bundledForm.get([field3]).setValidators([Validators.required]);
      this.bundledForm.get([field3]).updateValueAndValidity();

      this.bundledForm.get([field2]).setValidators([Validators.required]);
      this.bundledForm.get([field2]).updateValueAndValidity();

      this.weekdays = true;
    } else if (
      !this.bundledForm.value[field1] &&
      !this.bundledForm.value[field2] &&
      this.bundledForm.value[field3]
    ) {
      // if third
      this.bundledForm.get([field2]).setValidators([Validators.required]);
      this.bundledForm.get([field2]).updateValueAndValidity();

      this.bundledForm.get([field1]).setValidators([Validators.required]);
      this.bundledForm.get([field1]).updateValueAndValidity();
      this.weekdays = true;
    } else if (
      !this.bundledForm.value[field1] &&
      this.bundledForm.value[field2] &&
      !this.bundledForm.value[field3]
    ) {
      // if secound
      this.bundledForm.get([field3]).setValidators([Validators.required]);
      this.bundledForm.get([field3]).updateValueAndValidity();

      this.bundledForm.get([field1]).setValidators([Validators.required]);
      this.bundledForm.get([field1]).updateValueAndValidity();
      this.weekdays = true;
    } else if (
      this.bundledForm.value[field1] &&
      !this.bundledForm.value[field2] &&
      this.bundledForm.value[field3]
    ) {
      this.bundledForm.get([field2]).setValidators([Validators.required]);
      this.bundledForm.get([field2]).updateValueAndValidity();
      this.weekdays = true;
    } else if (
      this.bundledForm.value[field1] &&
      this.bundledForm.value[field2] &&
      !this.bundledForm.value[field3]
    ) {
      this.bundledForm.get([field3]).setValidators([Validators.required]);
      this.bundledForm.get([field3]).updateValueAndValidity();
      this.weekdays = true;
    } else if (
      !this.bundledForm.value[field1] &&
      this.bundledForm.value[field2] &&
      this.bundledForm.value[field3]
    ) {
      this.bundledForm.get([field1]).setValidators([Validators.required]);
      this.bundledForm.get([field1]).updateValueAndValidity();
      this.weekdays = true;
    } else {
      //both validate true
      this.bundledForm.get([field1]).setErrors(null);
      this.bundledForm.get([field2]).setErrors(null);
      this.bundledForm.get([field3]).setErrors(null);
      this.weekdays = true;
    }
    //***** weekdays days true ? disable else enable mat-tab */
  }

  // ------------------------------------------------------------------------------------------------
  // @ Set form group key and if edit category - get detail by id
  // ------------------------------------------------------------------------------------------------
  ngOnInit() {
    this.bundledForm = this.fb.group({
      uid: [''],
      mondayby: [''],
      mondaycutoff: [''],
      mondaymaximumorders: [''],
      tuesdayby: [''],
      tuesdaycutoff: [''],
      tuesdaymaximumorders: [''],
      wednesdayby: [''],
      wednesdaycutoff: [''],
      wednesdaymaximumorders: [''],
      thursdayby: [''],
      thursdaycutoff: [''],
      thursdaymaximumorders: [''],
      fridayby: [''],
      fridaycutoff: [''],
      fridaymaximumorders: [''],
      saturdayby: [''],
      saturdaycutoff: [''],
      saturdaymaximumorders: [''],
      sundayby: [''],
      sundaycutoff: [''],
      sundaymaximumorders: [''],
    });

    this.onDemandForm = this.fb.group({
      uid: [''],
      slotinterval: ['', Validators.required],
      ondemandcutoff: ['', Validators.required],
      maximumorders: ['', Validators.required],
    });
  }

  submitForm(type = '') {
    if (type == "onDemandForm") {
      // check form type valid
      this.formType = "ondemand";
      if (this.onDemandForm.invalid) {
        this.onDemandForm.markAllAsTouched();
        return;
      }
    } else {
      this.formType = "bundled";
      if (this.bundledForm.invalid) {
        this.bundledForm.markAllAsTouched();
        return;
      }
      if (this.createFormattedArray() == '') {
        // invalid form
        this.notifyService.showError(
          this.translate.instant(OrdersMessages.invalidForm),
          this.translate.instant(OrdersMessages.error)
        );
        return;
      }
    }

    var FormData = {
      uid: this.id ? this.id : 0,
      type: this.formType,
      slotinterval:
        this.formType === BranchDetailsFormControls.formType.ondemand
          ? this.onDemandForm.value.slotinterval
          : '',
      maximumorders:
        this.formType === BranchDetailsFormControls.formType.ondemand
          ? this.onDemandForm.value.maximumorders
          : '',
      ondemandcutoff:
        this.formType === BranchDetailsFormControls.formType.ondemand
          ? this.onDemandForm.value.ondemandcutoff
          : '',
      mondayby:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.mondayby : '',
      mondaycutoff:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.mondaycutoff : '',
      mondaymaximumorders:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.mondaymaximumorders
          : '',
      tuesdayby:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.tuesdayby : '',
      tuesdaycutoff:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.tuesdaycutoff : '',
      tuesdaymaximumorders:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.tuesdaymaximumorders
          : '',
      wednesdayby:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.wednesdayby : '',
      wednesdaycutoff:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.wednesdaycutoff
          : '',
      wednesdaymaximumorders:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.wednesdaymaximumorders
          : '',
      thursdayby:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.thursdayby : '',
      thursdaycutoff:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.thursdaycutoff
          : '',
      thursdaymaximumorders:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.thursdaymaximumorders
          : '',
      fridayby:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.fridayby : '',
      fridaycutoff:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.fridaycutoff : '',
      fridaymaximumorders:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.fridaymaximumorders
          : '',
      saturdayby:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.saturdayby : '',
      saturdaycutoff:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.saturdaycutoff
          : '',
      saturdaymaximumorders:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.saturdaymaximumorders
          : '',
      sundayby:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.sundayby : '',
      sundaycutoff:
        this.formType === BranchDetailsFormControls.formType.bundled ? this.bundledForm.value.sundaycutoff : '',
      sundaymaximumorders:
        this.formType === BranchDetailsFormControls.formType.bundled
          ? this.bundledForm.value.sundaymaximumorders
          : '',
      value: this.formType === BranchDetailsFormControls.formType.bundled ? this.createFormattedArray() : '',
    };

    if (this.id) {
      this.updateDeliveryType(FormData);
    } else {
      this.addDeliveryType(FormData);
    }
  }

  onNoClick(res_id = null): void {
    this.dialogRef.close({ uid: res_id });
  }
  tabChanged = ($event) : void => {
    console.log('tabChangeEvent => ', $event); 
    this.selectedTabindex =  $event.index;
}
  // ------------------------------------------------------------------------------------------------
  // @ If this is update form, get user details and update form group
  // ------------------------------------------------------------------------------------------------
  getDeliveryType(index: number) {
    console.log("getDeliveryType");
    this.branchService.getDeliveryType(index).subscribe(
      (response: any) => {
        this.deliveryType = response.type;
        if (response.type == BranchDetailsFormControls.formType.ondemand) {
          this.selectedTab = 1;
        }
        this.onDemandForm.patchValue(response);
        this.bundledForm.patchValue(response);
      },
      (err) => {
        console.log(err);
        // this.errors = err;
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ Add
  // ------------------------------------------------------------------------------------------------
  addDeliveryType(data) {
    this.branchService.addDeliveryType(data).subscribe(
      (response: any) => {
        if (response.success == true) {
          this.onNoClick(response.data.uid); // store result id
          this.notifyService.showSuccess(
            this.translate.instant(productMessage.add),
            this.translate.instant(OrdersMessages.success)
          );
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
      },
      (err) => {
        //	this.errors = err;
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ Update
  // ------------------------------------------------------------------------------------------------
  updateDeliveryType(data) {
    this.branchService.updateDeliveryType(data).subscribe(
      (response: any) => {
        if (response.success == true) {
          // updated
          this.onNoClick(response.data.uid); // store result id
          this.notifyService.showSuccess(
            this.translate.instant(OrdersMessages.update),
            this.translate.instant(OrdersMessages.success)
          );
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
      },
      (err) => {
        //  this.errors = err;
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ Delete
  // ------------------------------------------------------------------------------------------------
  deleteDeliveryType(slotId: number) {
    if (confirm(this.translate.instant(OrdersMessages.confirmDelete))) {
      this.branchService.deleteDeliveryType(slotId).subscribe(
        (response: any) => {
          if (response.success == true) {
            // delete
            this.notifyService.showSuccess(
              this.translate.instant(OrdersMessages.delete),
              this.translate.instant(OrdersMessages.success)
            );
            this.onNoClick(null);
          } else if (
            response.success == false &&
            response.code == 'timeslot_Associated'
          ) {
            // Associate
            this.notifyService.showError(
              this.translate.instant(OrdersMessages.timeslotAssociated),
              this.translate.instant(OrdersMessages.error)
            );
          }
        },
        (err) => {
          // this.errors = err;
        }
      );
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  // ------------------------------------------------------------------------------------------------
  // @ Create formatted array for timeslot calender on mobile app site
  // ------------------------------------------------------------------------------------------------
  createFormattedArray() {
    var mondayClosed = false;
    var tuesdayClosed = false;
    var wednesdayClosed = false;
    var thursdayClosed = false;
    var fridayClosed = false;
    var saturdayClosed = false;
    var sundayClosed = false;

    if (
      (this.bundledForm.value.mondayby == null &&
        this.bundledForm.value.mondaycutoff == null) ||
      (this.bundledForm.value.mondayby == '' &&
        this.bundledForm.value.mondaycutoff == '')
    ) {
      var mondayClosed = true;
    }

    if (
      (this.bundledForm.value.tuesdayby == null &&
        this.bundledForm.value.tuesdaycutoff == null) ||
      (this.bundledForm.value.tuesdayby == '' &&
        this.bundledForm.value.tuesdaycutoff == '')
    ) {
      var tuesdayClosed = true;
    }

    if (
      (this.bundledForm.value.wednesdayby == null &&
        this.bundledForm.value.wednesdaycutoff == null) ||
      (this.bundledForm.value.wednesdayby == '' &&
        this.bundledForm.value.wednesdaycutoff == '')
    ) {
      var wednesdayClosed = true;
    }

    if (
      (this.bundledForm.value.thursdayby == null &&
        this.bundledForm.value.thursdaycutoff == null) ||
      (this.bundledForm.value.thursdayby == '' &&
        this.bundledForm.value.thursdaycutoff == '')
    ) {
      var thursdayClosed = true;
    }

    if (
      (this.bundledForm.value.fridayby == null &&
        this.bundledForm.value.fridaycutoff == null) ||
      (this.bundledForm.value.fridayby == '' &&
        this.bundledForm.value.fridaycutoff == '')
    ) {
      var fridayClosed = true;
    }

    if (
      (this.bundledForm.value.saturdayby == null &&
        this.bundledForm.value.saturdaycutoff == null) ||
      (this.bundledForm.value.saturdayby == '' &&
        this.bundledForm.value.saturdaycutoff == '')
    ) {
      var saturdayClosed = true;
    }

    if (
      (this.bundledForm.value.sundayby == null &&
        this.bundledForm.value.sundaycutoff == null) ||
      (this.bundledForm.value.sundayby == '' &&
        this.bundledForm.value.sundaycutoff == '')
    ) {
      var sundayClosed = true;
    }

    if (
      mondayClosed == true &&
      tuesdayClosed == true &&
      wednesdayClosed == true &&
      thursdayClosed == true &&
      fridayClosed == true &&
      saturdayClosed == true &&
      sundayClosed == true
    ) {
      return '';
    }

    var weekDataArr = [
      {
        weekday: 1,
        cut_off: this.bundledForm.value.mondaycutoff,
        from: this.bundledForm.value.mondayby,
        maximumorders: this.bundledForm.value.mondaymaximumorders,
        to: '',
        closed: mondayClosed,
        weekday_formatted: 'monday',
      },
      {
        weekday: 2,
        cut_off: this.bundledForm.value.tuesdaycutoff,
        from: this.bundledForm.value.tuesdayby,
        maximumorders: this.bundledForm.value.tuesdaymaximumorders,
        to: '',
        closed: tuesdayClosed,
        weekday_formatted: 'tuesday',
      },
      {
        weekday: 3,
        cut_off: this.bundledForm.value.wednesdaycutoff,
        from: this.bundledForm.value.wednesdayby,
        maximumorders: this.bundledForm.value.wednesdaymaximumorders,
        to: '',
        closed: wednesdayClosed,
        weekday_formatted: 'wednesday',
      },
      {
        weekday: 4,
        cut_off: this.bundledForm.value.thursdaycutoff,
        from: this.bundledForm.value.thursdayby,
        maximumorders: this.bundledForm.value.thursdaymaximumorders,
        to: '',
        closed: thursdayClosed,
        weekday_formatted: 'thursday',
      },
      {
        weekday: 5,
        cut_off: this.bundledForm.value.fridaycutoff,
        from: this.bundledForm.value.fridayby,
        maximumorders: this.bundledForm.value.fridaymaximumorders,
        to: '',
        closed: fridayClosed,
        weekday_formatted: 'friday',
      },
      {
        weekday: 6,
        cut_off: this.bundledForm.value.saturdaycutoff,
        from: this.bundledForm.value.saturdayby,
        maximumorders: this.bundledForm.value.saturdaymaximumorders,
        to: '',
        closed: saturdayClosed,
        weekday_formatted: 'saturday',
      },
      {
        weekday: 7,
        cut_off: this.bundledForm.value.sundaycutoff,
        from: this.bundledForm.value.sundayby,
        maximumorders: this.bundledForm.value.sundaymaximumorders,
        to: '',
        closed: sundayClosed,
        weekday_formatted: 'sunday',
      },
    ];
    return JSON.stringify(weekDataArr);
  }
}
