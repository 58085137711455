import {
  Component,
  Input,
  AfterViewInit,
  OnInit,
  ViewChild,
  NgZone,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Branch } from '../../models/branch';
import { BranchService } from '../../services/branch.service';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import {
  ColumnConstant,
  CheckBoxConstant,
  ValueConstant,
  PaginatorPerPageLabel,
  ButtonConstant,
  HeaderColumnConstant
} from './../../../constants/lable.constant';
import { routes } from 'src/app/consts';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-branch-table',
  templateUrl: './branch-table.component.html',
  styleUrls: ['./branch-table.component.scss'],
})
export class BranchTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() branchTableData: Branch[];

  public navigation: typeof routes = routes;

  public buttonConstant = ButtonConstant;
  public branchTableData$: Observable<Branch[]>;
  public errors: string;
  public pageSize: number;
  public resultsLength = ValueConstant.resultsLength;
  public defaultPageSizeOptions = ValueConstant.defaultPageSizeOptions;
  public defaultPageSize = ValueConstant.defaultPageSize;
  public displayedColumns= ColumnConstant.BranchTableColumns;
  public displayedHeaderColumns= HeaderColumnConstant.BranchTableColumns;
  public dataSource: MatTableDataSource<Branch>;
  public selection = new SelectionModel<Branch>(true, []);
  public isShowFilterInput = false;
  showLoader: Boolean = true;

  form: FormGroup;

  constructor(
    private translate: TranslateService,
    private service: BranchService,
    private notifyService: NotificationService,
    private _ngZone: NgZone,
    private fb: FormBuilder
  ) {
    this.branchTableData$ = service.getBranches();
    this.service.formData = '';
    this.service.deliveryAreaID = '';
    this.service.deliverytypeid = '';
    //  translate.setDefaultLang('en');

    this.form = this.fb.group({
      name: new FormControl(''),
      address: new FormControl(''),
      online: new FormControl(''),
      phone: new FormControl('')
    });
  }

  public ngAfterViewInit(): void {
    this.service.getBranches().subscribe(
      (data) => {
        this.showLoader = false;
        this.resultsLength = data.length;
        this.dataSource = new MatTableDataSource<any>(data);
        this.dataSource.paginator = this.paginator;

        this.dataSource.filterPredicate = ((data, filter) => {
          var onlinestat = data.online ?  'online' : 'Offline';
          const a = !filter.name || data.name.toLowerCase().includes(filter.name);
          const b = !filter.address || data.address.toLowerCase().includes(filter.address);
          const c = !filter.online || onlinestat.toLowerCase().includes(filter.online);
          const d = !filter.phone || data.phone.toLowerCase().includes(filter.phone);
          return a && b && c && d;
        }) as (PeriodicElement, string) => boolean;

        // Small and Caps sorting
        this.dataSource.sortingDataAccessor = (
          data: any,
          sortHeaderId: string
        ): string => {
          if (typeof data[sortHeaderId] === 'string') {
            return data[sortHeaderId].toLocaleLowerCase();
          }

          return data[sortHeaderId];
        };
        // Small and Caps sorting

        this.dataSource.sort = this.sort;
        this.getAndInitTranslations();
      },
      (err) => {
        this.errors = err;
      }
    );

    this.form.valueChanges.subscribe((value) => {
      const filter = {
        ...value,
        address: value.address.trim().toLowerCase(),
        online: value.online.trim().toLowerCase(),
        name: value.name.trim().toLowerCase(),
      } as string;
      this.dataSource.filter = filter;
    });
  }

  getAndInitTranslations() {
    let of = this.translate.instant(PaginatorPerPageLabel.of);

    const dutchRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length == 0 || pageSize == 0) {
        return `0 ${of} ${length}`;
      }
      length = Math.max(length, 0);
      this.pageSize = this.service.pageLength;
      pageSize = this.pageSize ? this.pageSize : pageSize;
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
    };

    this.paginator._intl.itemsPerPageLabel = this.translate.instant(
      PaginatorPerPageLabel.itemsPerPage
    );
    this.paginator._intl.firstPageLabel = this.translate.instant(
      PaginatorPerPageLabel.firstPage
    );
    this.paginator._intl.lastPageLabel = this.translate.instant(
      PaginatorPerPageLabel.lastPage
    );
    this.paginator._intl.nextPageLabel = this.translate.instant(
      PaginatorPerPageLabel.nextPage
    );
    this.paginator._intl.previousPageLabel = this.translate.instant(
      PaginatorPerPageLabel.previousPage
    );
    this.paginator._intl.getRangeLabel = dutchRangeLabel;

    this._ngZone.run(() => {
      this.pageSize = this.service.pageLength;
    });
  }

  onPage(event: PageEvent,key) {
    if(key==1)
    {
    this.service.pageLength = event.pageSize;
    this.pageSize = this.service.pageLength;
    }

    else
    {
      this.service.pageLength = this.resultsLength;
      this.pageSize = this.service.pageLength;
      this.service.getBranches().subscribe(
        (data) => {
          this.resultsLength = data.length;
          this.dataSource = new MatTableDataSource<any>(data);
          this.dataSource.paginator = this.paginator;
  
          this.dataSource.filterPredicate = ((data, filter) => {
            var onlinestat = data.online ?  'online' : 'Offline';
            const a = !filter.name || data.name.toLowerCase().includes(filter.name);
            const b = !filter.address || data.address.toLowerCase().includes(filter.address);
            const c = !filter.online || onlinestat.toLowerCase().includes(filter.online);
            const d = !filter.phone || data.phone.toLowerCase().includes(filter.phone);
            return a && b && c && d;
          }) as (PeriodicElement, string) => boolean;
  
          // Small and Caps sorting
          this.dataSource.sortingDataAccessor = (
            data: any,
            sortHeaderId: string
          ): string => {
            if (typeof data[sortHeaderId] === 'string') {
              return data[sortHeaderId].toLocaleLowerCase();
            }
  
            return data[sortHeaderId];
          };
          // Small and Caps sorting
  
          this.dataSource.sort = this.sort;
          this.getAndInitTranslations();
        },
        (err) => {
          this.errors = err;
        }
      );
  
      this.form.valueChanges.subscribe((value) => {
        const filter = {
          ...value,
          address: value.address.trim().toLowerCase(),
          online: value.online.trim().toLowerCase(),
          name: value.name.trim().toLowerCase(),
        } as string;
        this.dataSource.filter = filter;
      });
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: any): string {
    if (!row) {
      return `${
        this.isAllSelected()
          ? CheckBoxConstant.select
          : CheckBoxConstant.deselect
      } all`;
    }
    return `${
      this.selection.isSelected(row)
        ? CheckBoxConstant.deselect
        : CheckBoxConstant.select
    } row ${row.position + 1}`;
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public showFilterInput(): void {
    this.isShowFilterInput = !this.isShowFilterInput;
    this.dataSource = new MatTableDataSource<Branch>(this.branchTableData);
  }

  getFirstEmail(phones) {
    let emailArray = eval(phones);
    return emailArray;
  }
}
