import { Component, EventEmitter, Input, Output } from '@angular/core';

import { routes } from '../../../../consts';
import { User } from '../../../../pages/auth/models';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  @Input() user: User;
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  user_info : any;
  constructor(private translate: TranslateService, private router: Router, private route: ActivatedRoute, private notifyService : NotificationService) {
    const currentLanguage = this.translate.getBrowserLang();
    translate.setDefaultLang(currentLanguage);
    this.user_info = JSON.parse(window.localStorage.getItem('appcmsUser')); //Getting local storage
  }

  public routes: typeof routes = routes;
  public flatlogicEmail: string = "https://flatlogic.com";

  public signOutEmit(): void {
    this.notifyService.showSuccess(this.translate.instant('pages.common.inputValidation.logoutSuccess'), "Success");
    this.signOut.emit();
    this.router.navigate(['/']);
  }

  profile() {
    this.router.navigate(['/profile']);
  }

}
