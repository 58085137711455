<p class="heading">
  <strong
    *ngIf="id">{{'pages.common.buttons.update' | translate}}&nbsp;{{'pages.branches.branchDetail.input.'+dialogType | translate}}</strong>
  <strong
    *ngIf="!id">{{'pages.common.buttons.add' | translate}}&nbsp;{{'pages.branches.branchDetail.input.'+dialogType | translate}}</strong>
  <mat-icon mat-dialog-close>close</mat-icon>
</p>
<form (ngSubmit)="submitForm('timeSlotHolidayForm')" *ngIf="dialogType=='openingTimesPublicHolidaysDelivery' ||
 dialogType=='openingTimesPublicHolidaysPickup'" [formGroup]="timeSlotHolidayForm">
  <div class="frm-box-outer">
    <div class="frm-box">
      <div class="form-group">
        <label class="form-label">{{'pages.branches.timeSlotDialog.input.title' | translate}}</label>
        <input type="text" class="form-control"  formControlName="title">
        <div class="feedback"
          *ngIf="timeSlotHolidayForm.controls['title'].invalid && (timeSlotHolidayForm.controls['title'].dirty || timeSlotHolidayForm.controls['title'].touched)">
        </div>
        <div
          *ngIf="timeSlotHolidayForm.controls['title']?.errors && (timeSlotHolidayForm.controls['title'].dirty || timeSlotHolidayForm.controls['title'].touched)"
          class="feedback">
          <div *ngIf="timeSlotHolidayForm.controls['title'].errors.required">
            {{'pages.branches.timeSlotDialog.inputValidation.title.required' | translate}}</div>
          <div *ngIf="timeSlotHolidayForm.controls['title'].errors.pattern">
            {{'pages.branches.timeSlotDialog.inputValidation.title.whitespace' | translate}}</div>
        </div>
      </div>
      <div
        class="row topAndmargin">
        <div class="col">
          <label>{{'pages.branches.timeSlotDialog.input.date' | translate}}</label>
          <mat-form-field class="form-control datePicker2" >
            <input disabled matInput [matDatepicker]="picker1" [min]="today" class="form-control bg-transparent"  formControlName="date" >
          <mat-datepicker-toggle disabled="false" matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker disabled="false" #picker1></mat-datepicker>
          </mat-form-field>
          <div *ngIf="timeSlotHolidayForm.controls['date']?.errors" class="feedback">
            <div *ngIf="timeSlotHolidayForm.controls['date'].errors.required">
              {{'pages.branches.timeSlotDialog.inputValidation.date' | translate}}</div>
          </div>
        </div>
        <div class="col">
          <label>{{'pages.branches.timeSlotDialog.input.closeDay' | translate}}</label>
          <mat-checkbox formControlName="closeDay"  (change)="checkCheckBoxvalue($event)" class="form-control custom-frame" style="right: 87px;">
          </mat-checkbox>
        </div>
      </div>
      <div class="row topAndmargin" *ngIf="!showHideTime">
        <label class="form-label lable-open-on">{{'pages.branches.timeSlotDialog.input.openOn' | translate}}</label>
        <div class="col">
          <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
          <input type="text" formControlName="fromTime" class="form-control" format='24' [ngxMatTimepicker]="fromTime"
            (ngModelChange)="conditionForHoliday('fromTime', 'toTime')" readonly>
          <ngx-mat-timepicker #fromTime></ngx-mat-timepicker>
          <div *ngIf="timeSlotHolidayForm.controls['fromTime']?.errors" class="feedback">
            <div *ngIf="timeSlotHolidayForm.controls['fromTime'].errors.required">
              {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
          </div>
        </div>
        <div class="col">
          <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
          <input type="text" class="form-control" formControlName="toTime" format='24' [ngxMatTimepicker]="toTime"
            (ngModelChange)="conditionForHoliday('fromTime', 'toTime')" readonly>
          <ngx-mat-timepicker #toTime></ngx-mat-timepicker>
          <div *ngIf="timeSlotHolidayForm.controls['toTime']?.errors" class="feedback">
            <div *ngIf="timeSlotHolidayForm.controls['toTime'].errors.required">
              {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
            <div *ngIf="timeSlotHolidayForm.controls['toTime'].errors.invalid">
              {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button type="submit" class="fill-button" mat-flat-button> {{'pages.common.buttons.save' | translate}} </button>
        <span *ngIf ="dialogType=='openingTimesPublicHolidaysDelivery'">
          <button type="button" class="outline-button" mat-stroked-button (click)="deletePublicHoliday('openingTimesPublicHolidaysDelivery',data.publicHolidayId)" *ngIf="data.publicHolidayId">
            {{'pages.common.buttons.delete' | translate}} </button>
        </span>
        <span *ngIf ="dialogType=='openingTimesPublicHolidaysPickup'">
          <button type="button" class="outline-button" mat-stroked-button (click)="deletePublicHoliday('openingTimesPublicHolidaysPickup',data.publicHolidayId)" *ngIf="data.publicHolidayId">
            {{'pages.common.buttons.delete' | translate}} </button>
        </span>

      </div>
    </div>
  </div>
</form>

<form [formGroup]="timeSlotForm" (ngSubmit)="submitForm('istimeSlotForm')"  *ngIf="dialogType=='openingTimesDeliveryStandard' || dialogType=='openingTimesPickupStandard' ||
dialogType=='openingTimesDeliveryAlternative' || dialogType=='openingTimesPickupAlternative'">
  <div class="frm-box-outer">
    <div class="frm-box">
      <div class="form-group">
        <label class="form-label">{{'pages.branches.timeSlotDialog.input.title' | translate}}</label>
        <input type="text" class="form-control"  formControlName="title">
        <div class="feedback"
          *ngIf="timeSlotForm.controls['title'].invalid && (timeSlotForm.controls['title'].dirty || timeSlotForm.controls['title'].touched)">
        </div>
        <div
          *ngIf="timeSlotForm.controls['title']?.errors && (timeSlotForm.controls['title'].dirty || timeSlotForm.controls['title'].touched)"
          class="feedback">
          <div *ngIf="timeSlotForm.controls['title'].errors.required">
            {{'pages.branches.timeSlotDialog.inputValidation.title.required' | translate}}</div>
          <div *ngIf="timeSlotForm.controls['title'].errors.pattern">
            {{'pages.branches.timeSlotDialog.inputValidation.title.whitespace' | translate}}</div>
        </div>
      </div>
      <label class="form-label lable-open-on">{{'pages.branches.timeSlotDialog.input.openOn' | translate}}</label>
      <mat-tab-group mat-align-tabs="center"  >
        <mat-tab label="{{'pages.branches.timeSlotDialog.input.mon' | translate}}" [disabled]="weekdays">
          <div class="row topAndmargin">
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
              <input type="text" formControlName="mondayfrom" class="form-control" format='24'
                [ngxMatTimepicker]="mondayfrom" (ngModelChange)="condition('mondayfrom', 'mondayTo')" readonly>
              <ngx-mat-timepicker #mondayfrom></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['mondayfrom']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['mondayfrom'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
              </div>
            </div>
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
              <input type="text" class="form-control" formControlName="mondayTo" format='24'
                [ngxMatTimepicker]="mondayTo" (ngModelChange)="condition('mondayfrom', 'mondayTo')" readonly>
              <ngx-mat-timepicker #mondayTo></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['mondayTo']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['mondayTo'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
                <div *ngIf="timeSlotForm.controls['mondayTo'].errors.invalid">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{'pages.branches.timeSlotDialog.input.tue' | translate}}" [disabled]="weekdays">
          <div class="row topAndmargin">
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
              <input type="text" class="form-control" formControlName="tuesdayfrom" format='24'
                [ngxMatTimepicker]="tuesdayfrom" (ngModelChange)="condition('tuesdayfrom', 'tuesdayTo')" readonly>
              <ngx-mat-timepicker #tuesdayfrom></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['tuesdayfrom']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['tuesdayfrom'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
              </div>
            </div>
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
              <input type="text" class="form-control" formControlName="tuesdayTo" format='24'
                [ngxMatTimepicker]="tuesdayTo" (ngModelChange)="condition('tuesdayfrom', 'tuesdayTo')" readonly>
              <ngx-mat-timepicker #tuesdayTo></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['tuesdayTo']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['tuesdayTo'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
                <div *ngIf="timeSlotForm.controls['tuesdayTo'].errors.invalid">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{'pages.branches.timeSlotDialog.input.wed' | translate}}" [disabled]="weekdays">
          <div class="row topAndmargin">
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
              <input type="text" class="form-control" formControlName="wednesdayfrom" format='24'
                [ngxMatTimepicker]="wednesdayfrom" (ngModelChange)="condition('wednesdayfrom', 'wednesdayTo')" readonly>
              <ngx-mat-timepicker #wednesdayfrom></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['wednesdayfrom']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['wednesdayfrom'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
              </div>
            </div>
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
              <input type="text" class="form-control" formControlName="wednesdayTo" format='24'
                [ngxMatTimepicker]="wednesdayTo" (ngModelChange)="condition('wednesdayfrom', 'wednesdayTo')" readonly>
              <ngx-mat-timepicker #wednesdayTo></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['wednesdayTo']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['wednesdayTo'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
                <div *ngIf="timeSlotForm.controls['wednesdayTo'].errors.invalid">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{'pages.branches.timeSlotDialog.input.thu' | translate}}" [disabled]="weekdays">
          <div class="row topAndmargin">
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
              <input type="text" class="form-control" formControlName="thursdayfrom" format='24'
                [ngxMatTimepicker]="thursdayfrom" (ngModelChange)="condition('thursdayfrom', 'thursdayTo')" readonly>
              <ngx-mat-timepicker #thursdayfrom></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['thursdayfrom']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['thursdayfrom'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
              </div>
            </div>
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
              <input type="text" class="form-control" formControlName="thursdayTo" format='24'
                [ngxMatTimepicker]="thursdayTo" (ngModelChange)="condition('thursdayfrom', 'thursdayTo')" readonly>
              <ngx-mat-timepicker #thursdayTo></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['thursdayTo']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['thursdayTo'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
                <div *ngIf="timeSlotForm.controls['thursdayTo'].errors.invalid">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{'pages.branches.timeSlotDialog.input.fri' | translate}}" [disabled]="weekdays">
          <div class="row topAndmargin">
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
              <input type="text" class="form-control" formControlName="fridayfrom" format='24'
                [ngxMatTimepicker]="fridayfrom" (ngModelChange)="condition('fridayfrom', 'fridayTo')" readonly>
              <ngx-mat-timepicker #fridayfrom></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['fridayfrom']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['fridayfrom'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
              </div>
            </div>
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
              <input type="text" class="form-control" formControlName="fridayTo" format='24'
                [ngxMatTimepicker]="fridayTo" (ngModelChange)="condition('fridayfrom', 'fridayTo')" readonly>
              <ngx-mat-timepicker #fridayTo></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['fridayTo']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['fridayTo'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
                <div *ngIf="timeSlotForm.controls['fridayTo'].errors.invalid">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{'pages.branches.timeSlotDialog.input.sat' | translate}}" [disabled]="weekdays">
          <div class="row topAndmargin">
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
              <input type="text" class="form-control" formControlName="saturdayfrom" format='24'
                [ngxMatTimepicker]="saturdayfrom" (ngModelChange)="condition('saturdayfrom', 'saturdayTo')" readonly>
              <ngx-mat-timepicker #saturdayfrom></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['saturdayfrom']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['saturdayfrom'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
              </div>
            </div>
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
              <input type="text" class="form-control" formControlName="saturdayTo" format='24'
                [ngxMatTimepicker]="saturdayTo" (ngModelChange)="condition('saturdayfrom', 'saturdayTo')" readonly>
              <ngx-mat-timepicker #saturdayTo></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['saturdayTo']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['saturdayTo'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
                <div *ngIf="timeSlotForm.controls['saturdayTo'].errors.invalid">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="{{'pages.branches.timeSlotDialog.input.sun' | translate}}" [disabled]="weekdays">
          <div class="row topAndmargin">
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.from' | translate}}</label>
              <input type="text" class="form-control" formControlName="sundayfrom" format='24'
                [ngxMatTimepicker]="sundayfrom" (ngModelChange)="condition('sundayfrom', 'sundayTo')" readonly>
              <ngx-mat-timepicker #sundayfrom></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['sundayfrom']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['sundayfrom'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.from.required' | translate}}</div>
              </div>
            </div>
            <div class="col">
              <label>{{'pages.branches.timeSlotDialog.input.to' | translate}}</label>
              <input type="text" class="form-control" formControlName="sundayTo" format='24'
                [ngxMatTimepicker]="sundayTo" (ngModelChange)="condition('sundayfrom', 'sundayTo')" readonly>
              <ngx-mat-timepicker #sundayTo></ngx-mat-timepicker>
              <div *ngIf="timeSlotForm.controls['sundayTo']?.errors" class="feedback">
                <div *ngIf="timeSlotForm.controls['sundayTo'].errors.required">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.required' | translate}}</div>
                <div *ngIf="timeSlotForm.controls['sundayTo'].errors.invalid">
                  {{'pages.branches.timeSlotDialog.inputValidation.to.invalid' | translate}}</div>
              </div>
            </div>
          </div>
        </mat-tab>

      </mat-tab-group>
      <div class="form-group">
        <button type="submit" class="fill-button" mat-flat-button> {{'pages.common.buttons.save' | translate}} </button>
        <button type="button" class="outline-button" mat-stroked-button  mat-dialog-close>
          {{'pages.common.buttons.cancel' | translate}} </button>
      </div>
    </div>
  </div>
</form>
<!-- {{ timeSlotForm.value | json }} -->
