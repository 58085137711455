export class BranchDetailsMessage{
public static customEmailValid = 'pages.branches.branchDetail.inputValidation.customEmailValid.valid';
public static emailRequired = 'pages.branches.branchDetail.inputValidation.customEmailValid.required';
public static maxEmail = 'pages.branches.branchDetail.inputValidation.customEmailValid.maxEmail';
public static maxPhone= 'pages.branches.branchDetail.inputValidation.customPhoneValid.maxPhone';
public static maxLength ='pages.branches.branchDetail.inputValidation.customPhoneValid.maxLength';
public static minLength= 'pages.branches.branchDetail.inputValidation.customPhoneValid.minLength';
public static phoneRequired = 'pages.branches.branchDetail.inputValidation.customPhoneValid.required';
public static phoneValid = 'pages.branches.branchDetail.inputValidation.customPhoneValid.valid';
public static branchExist='pages.common.inputValidation.branchExist';
public static branchAssociated = 'pages.common.inputValidation.branchAssociated';
public static deliveryOptions = 'pages.branches.branchDetail.inputValidation.deliveryOptions.requiredFirst';
public static responseCode ={
  branch_Deleted : 'branch_Deleted',
  branch_Associated:'branch_Associated',
  supplier_Updated:'supplier_Updated',
  supplier_Exist:'supplier_Exist',
  supplier_Deleted:'supplier_Deleted',
  supplier_Associated:'supplier_Associated',
  supplier_Added:'supplier_Added',
  staff_Added:'staff_Added',
  staff_Exist:'staff_Exist',
  timeslot_Associated:"timeslot_Associated"
}
}

export class OrdersMessages{
  public static confirmStatus= "pages.common.inputValidation.confirnStatus";
  public static update ="pages.common.inputValidation.update";
  public static statusUpdate= 'pages.common.inputValidation.statusUpdate';
  public static confirmDelete = 'pages.common.inputValidation.confirnDelete';
  public static delete ='pages.common.inputValidation.delete';
  public static success ='pages.common.statusEnum.success';
  public static error ='pages.common.statusEnum.error'
  public static warning = 'pages.common.statusEnum.warning'
  public static status = {
    active: 'ACTIVE',
    inactive:'INACTIVE',
    Completed: 'Completed',
    Cancelled:'Cancelled',
    Received:'Received',
    Inpreparation:'In preparation',
    Delivered:'Delivered'
  }
  public static minOrderProduct ='pages.common.inputValidation.minOrderProduct';
  public static timeslotAssociated = 'pages.common.inputValidation.timeslotAssociated';
  public static invalidForm = 'pages.common.inputValidation.invalidForm';
  public static to_invalid= 'pages.branches.timeSlotDialog.inputValidation.to.invalid'
}

export class productMessage{
  public static productExist ="pages.common.inputValidation.productExist";
  public static add ='pages.common.inputValidation.add';
  public static availableStatusRequired= 'pages.product.productDetail.inputValidation.AvailableStatus.required';
  public static branchSelectRequired ='pages.product.productDetail.inputValidation.branchSelect.required';
  public static noBranch = 'pages.product.productDetail.input.noBranch';
  public static productImageRatio= 'pages.common.inputValidation.productImageRatio'
}

export class staffMessage{
  public static staffExist ='pages.common.inputValidation.staffExist';
  public static leavePageDialog ='pages.leavePageDialog.header'
}

export class supplierMessage{
  public static supplierExist ='pages.common.inputValidation.supplierExist';
  public static supplierAssociated = 'pages.common.inputValidation.supplierAssociated';
  public static supplierDate='pages.common.inputValidation.supplier_Invoive_dateSearch'
  public static supplierSucces='pages.common.inputValidation.supplierSucces'
  public static supplierordermail ='pages.common.inputValidation.supplierordermail'
}

export class categoriesMessages{
  public static imageSize = 'pages.common.inputValidation.imageSize';
  public static imageRatio = 'pages.common.inputValidation.imageRatio';
  public static categoryExist = 'pages.common.inputValidation.categoryExist';
  public static categoryAssociated = 'pages.common.inputValidation.categoryAssociated'
}

