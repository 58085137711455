import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbRoute = [];
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    // console.log(router.url)
    var routerLink = [];
    routerLink = router.url.split('/');
    routerLink.splice(0, 1);
    var previousIndexValue = [];
    for(var key in routerLink){
      previousIndexValue.push(routerLink[key])
        let convertToStr = previousIndexValue.toString().replace(/,/g, '/')
        this.breadcrumbRoute.push({
          title: routerLink[key],
          route: convertToStr
        })
    }
  }

  ngOnInit(): void {

  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }


}
