<app-layout>
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <mat-card class="remove-box-shadow bg-transparent">
    <mat-card-content>
      <div class="frm-header">
        <div>
          <span class="btn-back add-pointer" [routerLink]="navigation.BRANCHES" onclick="localStorage.removeItem('productList')"><img src="{{imgUrl.back_arrow}}"
              alt=""></span>
          <h4>{{'pages.branches.branchDetail.heading' | translate}}</h4>
        </div>
        <div class="action-button">
          <button class="header-outline-button" mat-stroked-button (click)="deleteBranch(id)" *ngIf="id">
            {{buttonConstant.delete | translate}} </button>
          <!-- <button class="header-outline-button" mat-stroked-button> Cancel </button> -->
          <button class="fill-button" mat-flat-button (click)="submitForm(true,myDialog)" *ngIf="id">
            {{buttonConstant.update | translate}} </button>
          <button class="fill-button" mat-flat-button (click)="submitForm(true,myDialog)" *ngIf="!id">
            {{buttonConstant.save | translate}} </button>
        </div>
      </div>
    </mat-card-content>
    <form [formGroup]="branchForm">
      <div class="row flex-nowrap">
        <div class="col-md-3">
          <div class="form-group position-relative">
            <label class="form-label">{{'pages.branches.branchDetail.input.name' | translate}}</label>
            <input type="text" class="form-control" formControlName="name">
            <div
              *ngIf="branchForm.controls['name']?.errors && (branchForm.controls['name'].dirty || branchForm.controls['name'].touched)"
              class="invalid-feed">
              <div *ngIf="branchForm.controls['name'].errors.required">
                {{'pages.branches.branchDetail.inputValidation.name.required' | translate}}</div>
              <div *ngIf="branchForm.controls['name'].errors.pattern">
                {{'pages.branches.branchDetail.inputValidation.name.whitespace' | translate}}</div>
              <div *ngIf="branchForm.controls['name'].errors.maxlength && !branchForm.controls['name'].errors.pattern">
                {{'pages.branches.branchDetail.inputValidation.name.maxLength' | translate}}</div>
              <div *ngIf="branchForm.controls['name'].errors.minlength && !branchForm.controls['name'].errors.pattern">
                {{'pages.branches.branchDetail.inputValidation.name.minLength' | translate}}</div>
            </div>
          </div>
          <div>



          </div>

          <div class="form-group position-relative">
            <label class="form-label">{{'pages.branches.branchDetail.input.address' | translate}}</label>
            <input type="text" class="form-control" formControlName="{{control.address}}"
              (input)="checkValidAddress($event.target.value, false);" #search />

            <div
              *ngIf="branchForm.controls[control.address]?.errors && (branchForm.controls[control.address].dirty || branchForm.controls[control.address].touched)"
              class="invalid-feed">
              <div *ngIf="branchForm.controls[control.address].errors.required">
                {{'pages.branches.branchDetail.inputValidation.address.required' | translate}}</div>
              <div *ngIf="branchForm.controls[control.address].errors.pattern">
                {{'pages.branches.branchDetail.inputValidation.address.whitespace' | translate}}</div>
              <div
                *ngIf="branchForm.controls[control.address].errors.maxlength && !branchForm.controls[control.address].errors.pattern">
                {{'pages.branches.branchDetail.inputValidation.address.maxLength' | translate}}</div>
              <div
                *ngIf="branchForm.controls[control.address].errors.minlength && !branchForm.controls[control.address].errors.pattern">
                {{'pages.branches.branchDetail.inputValidation.address.minLength' | translate}}</div>
              <div *ngIf="branchForm.controls[control.address].errors.invalid && !addressValid">
                {{'pages.branches.branchDetail.inputValidation.address.invalid' | translate}}</div>
            </div>
          </div>

          <div formArrayName="emails">
            <div class="form-group position-relative"
              *ngFor="let email of branchForm.get('emails').controls; let rr=index;" [formGroupName]="rr">
              <label class="form-label">{{'pages.branches.branchDetail.input.email' | translate}} {{ rr + 1 }} </label>
              <div class="position-relative">
                <input type="text" class="form-control" formControlName="email" maxlength="50">
                <span *ngIf="rr > 0" class="dd-delete" (click)="deleteEmailField(rr)"></span>
                <div class="invalid-feed"
                  *ngIf="branchForm.controls['emails'].touched && emailValidItemData().success == 0 && emailValidItemData().index == rr">
                  {{ emailValidItemData().message }} </div>
              </div>
            </div>
            <div class="valid-feedback" *ngIf="maxEmailMessage">{{ emailValidItemData().message }} </div>
            <button (click)="addEmail($event)" class="outline-button" mat-stroked-button> +
              {{'pages.branches.branchDetail.buttons.add' | translate}} </button>
          </div>

          <div formArrayName="{{branchControl.phones}}">
            <div class="form-group position-relative" style="top:-7px;"
              *ngFor="let phone of branchForm.get(branchControl.phones).controls; let r=index;" [formGroupName]="r">
              <label class="form-label">{{'pages.branches.branchDetail.input.phone' | translate}} {{ r + 1 }} </label>
              <div class="position-relative">
                <input type="text" class="form-control" formControlName="phone" ng-change="checkPhone(thisValue, r)"
                  ng-model="thisValue" >
                <span *ngIf="r > 0" class="dd-delete" (click)="deletePhoneField(r)"></span>
              </div>

              <div class="invalid-feed"
                *ngIf="branchForm.controls['phones'].touched && phoneValidItemData().success == 0 && phoneValidItemData().index == r">
                {{ phoneValidItemData().message }} </div>
            </div>
            <!-- <div class="valid-feedback" *ngIf="maxPhoneMessage">{{ phoneValidItemData().message }} </div> -->
            <button style="top:-13px;" (click)="addPhone($event)" class="outline-button" mat-stroked-button> +
              {{'pages.branches.branchDetail.buttons.add' | translate}} </button>
          </div>
          <label class="form-label">{{'pages.branches.branchDetail.input.productName' | translate}}</label>
          <div class="form-group position-relative">
            <!-- ------------------------ -->
            <div>
              <div (mouseleave)="showDropDown = false">
                <button class="drop-toggle btn flat" (click)="showDropDown=!showDropDown">
                  <span> {{'pages.branches.branchDetail.input.select' | translate}} </span>
                  <i class="fa fa-angle-down"></i>
                </button>
                <div class="drop-show" *ngIf="showDropDown">
                  <label *ngFor="let a of productList">
                    <input type="checkbox" [checked]="a.checked"
                      (change)="getSelectedValue(a.checked, a.name, a.uid)" />
                    <span>{{a.name}}</span>
                  </label>
                </div>
              </div>
            </div>
            <!-- <select formControlName="uid" class="form-control" 
                 (change)="selectProduct($event.target.value)">
                    <option *ngFor="let item of productList" [value]="item.uid">{{item.name}}</option>
                </select> -->
            <!-- <multi-selector-dropdown
                  [list]="productList"
                  (shareCheckedList)="shareCheckedList($event)" 
                  (shareIndividualCheckedList)="shareIndividualCheckedList($event)"
                  >
              </multi-selector-dropdown> -->
            <!-- <input id="dropdown1" ej-dropdownlist dataSource="data" fields="fieldsvalues" showCheckbox="true"/> -->
          </div>
        </div>

        <div class="col-sm-9 col-md-6 offset-1 align-self-end">
          <div class="mb-5">
            <mat-checkbox class="custom-frame" [checked]="onlyForTableOrderCheck" (change)="onlyTableOrder()">
              {{ 'pages.common.statusEnum.qrForTableOrderOnly' | translate }}
            </mat-checkbox>
            <!-- </> -->
          </div>
          <div class="d-flex flex-column justify-content-between scroll-container">
            <mat-tab-group mat-align-tabs="center" class="branch-tab"
              [style.opacity]="onlyForTableOrderCheck ? '0.5' : '1'"
              [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
              <mat-tab label="{{'pages.branches.branchDetail.input.delivers' | translate}}">
                <div class="row">
                  <div class="col-md-8">
                    <div class="form-group position-relative">
                      <!-- <label class="form-label">{{'pages.branches.branchDetail.input.deliveryOptions' | translate}}</label> -->
                      <div class="delivery-options" formGroupName="{{branchControl.deliveryOptions}}">
                        <mat-checkbox formControlName="delivers" class="custom-frame" (change)="changeDeliveryType()"
                          [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                          {{'pages.branches.branchDetail.input.delivers' | translate}}</mat-checkbox>
                        <div class="invalid-feed"
                          *ngIf="branchForm.controls[branchControl.deliveryOptions].touched && branchForm.controls[branchControl.deliveryOptions].errors && branchForm.controls[branchControl.deliveryOptions].errors.requireOneCheckboxToBeChecked">
                          {{'pages.product.productDetail.inputValidation.availableOn.required' | translate}}</div>
                      </div>
                    </div>
                    <button class="header-outline-button" type="button" mat-stroked-button
                      (click)="openDeliveryTypeDialog()" [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                      <span *ngIf="id">{{'pages.branches.branchDetail.buttons.editDeliveryTypes' | translate}} </span>
                      <span *ngIf="!id">{{'pages.branches.branchDetail.buttons.addDeliveryTypes' | translate}} </span>
                    </button>
                    <!-- validate to select delivery type -->
                    <div
                      *ngIf="branchForm.controls['deliverytypeid']?.errors && (branchForm.controls['deliverytypeid'].dirty || branchForm.controls['deliverytypeid'].touched)"
                      class="invalid-feed">
                      <div *ngIf="branchForm.controls['deliverytypeid'].errors.required">
                        {{'pages.branches.branchDetail.inputValidation.deliveryOptions.required' | translate}}</div>
                    </div>
                    <button *ngIf="this.branchForm.value.branchdeliveryareasId" class="fill-button mt-4" type="button"
                      (click)="submitForm(false,myDialog)" mat-flat-button
                      [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'"
                      [routerLink]="navigation.BRANCHES_DELIVERY_AREA+ this.branchForm.value.branchdeliveryareasId">
                      <span *ngIf="id">{{'pages.branches.branchDetail.buttons.viewDeliveryArea' | translate}}</span>
                      <span *ngIf="!id">{{'pages.branches.branchDetail.buttons.addDeliveryArea' | translate}}</span>
                    </button>
                    <button *ngIf="!this.branchForm.value.branchdeliveryareasId" class="fill-button mt-4" type="button"
                      (click)="submitForm(false,myDialog)" mat-flat-button
                      [routerLink]="navigation.BRANCHES_DELIVERY_AREA"
                      [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                      <span *ngIf="id">{{'pages.branches.branchDetail.buttons.viewDeliveryArea' | translate}}</span>
                      <span *ngIf="!id">{{'pages.branches.branchDetail.buttons.addDeliveryArea' | translate}}</span>
                    </button>
                    <div class="invalid-feed" *ngIf="branchForm.controls['branchdeliveryareasId'].invalid && (branchForm.controls['branchdeliveryareasId'].dirty
                    || branchForm.controls['branchdeliveryareasId'].touched)">
                      {{'pages.branches.branchDetail.inputValidation.DeliveryArea' | translate}}</div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group position-relative mt-4">
                      <mat-radio-group class="delivery-options-button" formControlName="openingTimesDelivery">
                        <table [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                          <tr>
                            <td>
                              <mat-radio-button value="deliveryStandard">
                                <mat-label class="slot-title">
                                  {{'pages.branches.branchDetail.input.openingTimesDeliveryStandard' |
                                  translate}}</mat-label>
                              </mat-radio-button>
                            </td>
                            <td>
                              <mat-label class="slot-time">{{standarddelivery?.title }}</mat-label>
                            </td>
                            <td width="83px">
                              <button (click)="openDialog('openingTimesDeliveryStandard')" type="button"
                                class="icon-btn"><img src="{{imgUrl.edit}}" alt=""></button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mat-radio-button class="mt-2" value="deliveryAlternative">
                                <mat-label class="slot-title">
                                  {{'pages.branches.branchDetail.input.openingTimesDeliveryAlternative' | translate}}
                                </mat-label>
                              </mat-radio-button>
                            </td>
                            <td>
                              <mat-label class="slot-time">{{alternateDelivery?.title }}</mat-label>

                            </td>
                            <td>
                              <button (click)="openDialog('openingTimesDeliveryAlternative')" type="button"
                                class="icon-btn"><img src="{{imgUrl.edit}}" alt=""></button>
                            </td>
                          </tr>
                        </table>
                      </mat-radio-group>

                      <div class="invalid-feed"
                        *ngIf="branchForm.controls['openingTimesDelivery'].invalid && (branchForm.controls['openingTimesDelivery'].dirty || branchForm.controls['openingTimesDelivery'].touched)"
                        [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                        {{'pages.branches.branchDetail.inputValidation.holiday.openingTimeStd' | translate}}</div>
                    </div>
                    <div class="form-group position-relative"
                      [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">

                      <mat-radio-button name="publicHolidayDelivery" checked="true" value="true">
                        <mat-label class="slot-title">
                          {{'pages.branches.branchDetail.input.openingTimesPublicHolidays' | translate}}</mat-label>
                      </mat-radio-button>

                    </div>
                  </div>
                  <div class="col-md-12">
                    <table [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                      <tr>
                        <th>{{'pages.branches.branchDetail.input.publicHoliday' | translate}}</th>
                        <th>{{'pages.branches.branchDetail.input.date' | translate}}</th>
                        <th>{{'pages.branches.branchDetail.input.startTime' | translate}}</th>
                        <th>{{'pages.branches.branchDetail.input.endTime' | translate}}</th>
                        <th>{{'pages.branches.branchDetail.input.closeDay' | translate}}</th>
                        <th width="83px">{{'pages.branches.branchDetail.input.editPin' | translate}}</th>
                      </tr>
                      <tr *ngFor="let data of publicholidaysdelivery">
                        <td>{{ data.title }}</td>
                        <td>{{ data.date | date:'dd-MM-yyyy'}}</td>
                        <td>{{ data.from }}</td>
                        <td>{{ data.to }}</td>
                        <td>
                          <mat-checkbox [checked]="data.closeDay" [value]="data.closeDay"
                            disabled="true"></mat-checkbox>
                        </td>
                        <td>
                          <button (click)="openDialog('openingTimesPublicHolidaysDelivery',data, data.id)" type="button"
                            class="icon-btn "><img src="{{imgUrl.edit}}" alt=""></button>
                        </td>
                      </tr>
                    </table>
                    <div class="branchAdd" [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                      <button (click)="openDialog('openingTimesPublicHolidaysDelivery')" class="outline-button"
                        mat-stroked-button> +
                        {{buttonConstant.branch_add | translate}} </button>
                    </div>

                  </div>
                </div>
              </mat-tab>
              <mat-tab label="{{'pages.branches.branchDetail.input.pickUp' | translate}}">
                <div class="row">
                  <div class="col-md-12">
                    <div class="pickup-options" formGroupName="{{branchControl.deliveryOptions}}"
                      [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                      <mat-checkbox formControlName="pickup" class="custom-frame" (change)="changeDeliveryType()">
                        {{'pages.branches.branchDetail.input.pickUp' | translate}}</mat-checkbox>
                    </div>
                    <p>{{'pages.branches.branchDetail.input.pickUpNote' | translate}}</p>
                  </div>
                  <div class="col-md-12" [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                    <div class="form-group position-relative  mt-4">
                      <mat-radio-group class="delivery-options-button" [(ngModel)]="selection"
                        formControlName="openingTimesPickup">
                        <table>
                          <tr>
                            <td>
                              <mat-radio-button value="pickUpStandard">
                                <mat-label class="slot-title">
                                  {{'pages.branches.branchDetail.input.openingTimesPickupStandard' |
                                  translate}}</mat-label>
                              </mat-radio-button>
                            </td>
                            <td>
                              <mat-label class="slot-time">{{standardPickup?.title}}</mat-label>
                            </td>
                            <td width="83px">
                              <button (click)="openDialog('openingTimesPickupStandard')" type="button"
                                class="icon-btn "><img src="{{imgUrl.edit}}" alt=""></button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <mat-radio-button class="mt-2" value="pickUpAlternative">
                                <mat-label class="slot-title">
                                  {{'pages.branches.branchDetail.input.openingTimesPickupAlternative' | translate}}
                                </mat-label>
                              </mat-radio-button>
                            </td>
                            <td>
                              <mat-label class="slot-time">{{alternatePickup?.title }}</mat-label>
                            </td>
                            <td>
                              <button (click)="openDialog('openingTimesPickupAlternative')" type="button"
                                class="icon-btn "><img src="{{imgUrl.edit}}" alt=""></button>
                            </td>
                          </tr>
                        </table>
                      </mat-radio-group>
                      <div class="invalid-feed" *ngIf="branchForm.controls['openingTimesPickup'].invalid && (branchForm.controls['openingTimesPickup'].dirty
                      || branchForm.controls['openingTimesPickup'].touched)">
                        {{'pages.branches.branchDetail.inputValidation.holiday.openingPickup' | translate}}</div>
                    </div>
                    <div class="form-group position-relative">
                      <mat-radio-button name="publicHolidayPickup" checked="true" value="true">
                        <mat-label class="slot-title">
                          {{'pages.branches.branchDetail.input.openingTimesPublicHolidays' | translate}}</mat-label>
                      </mat-radio-button>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div>
                      <table [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                        <tr>
                          <th>{{'pages.branches.branchDetail.input.publicHoliday' | translate}}</th>
                          <th>{{'pages.branches.branchDetail.input.date' | translate}}</th>
                          <th>{{'pages.branches.branchDetail.input.startTime' | translate}}</th>
                          <th>{{'pages.branches.branchDetail.input.endTime' | translate}}</th>
                          <th>{{'pages.branches.branchDetail.input.closeDay' | translate}}</th>
                          <!-- <th>{{'pages.branches.branchDetail.input.action' | translate}}</th> -->
                          <th width="83px">{{'pages.branches.branchDetail.input.editPin' | translate}}</th>
                        </tr>
                        <tr *ngFor="let data of publicholidayspickup">
                          <td>{{ data.title }}</td>
                          <td>{{ data.date | date:'dd-MM-yyyy'}}</td>
                          <td>{{ data.from }}</td>
                          <td>{{ data.to }}</td>
                          <td>
                            <mat-checkbox [checked]="data.closeDay" [value]="data.closeDay"
                              disabled="true"></mat-checkbox>
                          </td>
                          <td width="83px">
                            <button (click)="openDialog('openingTimesPublicHolidaysPickup',data, data.id)" type="button"
                              class="icon-btn "><img src="{{imgUrl.edit}}" alt=""></button>
                          </td>
                        </tr>
                      </table>
                      <div class="branchAdd" [style.pointer-events]="onlyForTableOrderCheck ?'none':'auto'">
                        <button (click)="openDialog('openingTimesPublicHolidaysPickup')" class="outline-button"
                          mat-stroked-button> +
                          {{buttonConstant.branch_add | translate}} </button>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>

            <div class="branch-actions">
              <mat-checkbox formControlName="online" (click)="onCheckboxClick($event)" class="custom-frame">
                {{ 'pages.common.statusEnum.goLive' | translate }}</mat-checkbox>

              <!-- <mat-checkbox  class="custom-frame">
                {{ 'pages.common.statusEnum.qrForTableOrderOnly' | translate }}</mat-checkbox> -->

              <button class="fill-button" mat-flat-button (click)="createQRCode()" *ngIf="id">
                QR-Code Bestellungen</button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </mat-card>

  <!-- Alert dialog -->
  <ng-template #myDialog>
    <div class="text-align-center">
      <h2 matDialogTitle> {{'pages.orders.orderTable.alert' | translate}}</h2>
      <mat-dialog-content>
        <p>{{'pages.branches.validationMessage.alert' | translate}}</p>
        <div class="dialog-footer"><button mat-button matDialogClose class="fill-button">{{'pages.orders.orderTable.ok'
            | translate}}</button></div>
      </mat-dialog-content>
    </div>
  </ng-template>
</app-layout>