<!-- <p>spinner works!</p> -->

<div class="spinner-container">
<mat-progress-spinner   
        color=""
        mode="indeterminate"
        value=50    
        class="small-spinner"
        >
</mat-progress-spinner>
</div>