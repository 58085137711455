export class ColumnConstant{
  public static BranchTableColumns = ['action','name', 'address', 'online', 'phone'];
  public static oredrTabelColumnSuperAdmin = ['action','checkbox', 'ordernumber','tenantOrderNumber', 'deliverytype', 'status', 'name', 'tenantName', 'street', 'branchName', 'price', 'orderplacedtime', 'deliverydatetime'];

  public static orderTabelColumn = ['action','checkbox', 'ordernumber','tenantOrderNumber', 'deliverytype', 'status', 'name','street','rechnungsadresse','branchName','payment' ,'price','orderplacedDate','orderplacedtime','deliverydate' ,'deliverytime'];

  // public static orderTabelColumn = ['action','checkbox', 'ordernumber', 'deliverytype', 'status', 'name', 'street','houseNumber','addressSupplement','zipCode','city','branchName','payment' ,'price','orderplacedDate','orderplacedtime','deliverydate' ,'deliverytime'];
  public static orderProductTable = ['action','image', 'price', 'amount', 'net', 'vat', 'gross'];
  public static categoris= ['Cheeses', 'Beverages']
  public static ProductTable=  ['action','name', 'categoryName', 'supplier','pricepickup','pricedelivery','priceaction'];
  public static staffColumns = ['action','lastName', 'firstName', 'email', 'phone', 'role'];
  public static subscriptionColumns = ['action','checkbox', 'ordernumber', 'orderstatus','status', 'planname', 'firstName', 'street', 'branchName', 'price', 'orderplacedtime', 'deliverydatetime'];
  public static SupplierColumns =  ['action','companyName', 'firstname', 'address', 'email', 'mobile', 'website'];
  public static supplierOrderColumn = ['productname', 'sku', 'price', 'amount','branchname', 'suppliername', 'date'];
  public static tenantColumns = ['action','lastName', 'firstName', 'email', 'phone', 'role'];
  public static categoryColumns = ['action','drag', 'image', 'categoryName'];
  public static statementColumns = ['checkbox', 'payment', 'totalpricegross7','mwst7','totalpricegross19','mwst19'];
  public static membersColumns =  [ 'action','lastName', 'firstName', 'city', 'phone','email','active'];
  public static evaluationsColumns = ['action', 'name', 'description'];
  public static couponsColumns =  ['action', 'description', 'couponcode', 'percentage', 'minimumValue','type','status', 'validFrom','validTo'];
  public static qrcodesColumns =  ['action', 'checkbox', 'qrcode', 'tablenumber', 'qrcodeimage'];
  public static notificationColumns = ['action','messagedate','messagetime', 'title', 'status','message'];

  public static updateLogsOrdersColumns = ['ordernumber','invoiceNumber','invoiceStatus','updates','orderplacedtime','deliverydatetime','deliverytype','paymentmethod','name','branch','deliveryaddress','billingaddress','billingName','productname','singleprice','number','netprice','vatrate','total','status'];
  public static updateLogsProductColumns = ['productId','updateDateTime','name','categoryNames','supplierName','cost','vat','pricepickup','pricedelivery','priceaction','promotionstartdate','promotionenddate','days','isbundle','bundlepickupprice','bundledeliveryprice','bundleproductlist'];
  public static updateLogsMemberColumns = ['memberId','updateDateTime','name','address','phone','email'];

  // 'changedate','changetime',
  public static productsColumns = ['drag', 'image', 'name'];

}

export class HeaderColumnConstant{
  public static BranchTableColumns = [];
  // public static oredrTabelColumnSuperAdmin = [ 'actionHeader','checkboxHeader', 'ordernumberHeader', 'deliverytypeHeader', 'statusHeader', 'nameHeader', 'tenantNameHeader', 'streetHeader','houseNumberHeader','addressSupplementHeader','zipCodeHeader','cityHeader','branchNameHeader','paymentHeader' ,'priceHeader', 'orderplacedDateHeader' ,'orderplacedtimeHeader','deliverydateHeader' ,'deliverytimeHeader'];
  public static oredrTabelColumnSuperAdmin = [ 'actionHeader','checkboxHeader', 'ordernumberHeader', 'tenantOrderNumberHeader','deliverytypeHeader', 'statusHeader', 'nameHeader', 'tenantNameHeader', 'streetHeader','rechnungsadresseHeader','branchNameHeader','paymentHeader' ,'priceHeader', 'orderplacedDateHeader' ,'orderplacedtimeHeader','deliverydateHeader' ,'deliverytimeHeader'];

  public static orderTabelColumn = ['actionHeader','checkboxHeader','ordernumberHeader','tenantOrderNumberHeader','deliverytypeHeader','statusHeader','nameHeader','streetHeader','rechnungsadresseHeader','branchNameHeader','paymentHeader','priceHeader','orderplacedDateHeader','orderplacedtimeHeader','deliverydateHeader','deliverytimeHeader'];
  public static orderProductTable = [];
  public static categoris= []
  public static ProductTable=  [];
  public static staffColumns = [];
  public static subscriptionColumns = [];
  public static SupplierColumns =  [];
  public static supplierOrderColumn = [];
  public static tenantColumns = ['action','lastName', 'firstName', 'email', 'phone', 'role'];
  public static membersColumns =  ['lastNameHeader','firstNameHeader','addressHeader','phoneHeader','emailHeader','activeHeader'];
  public static categoryColumns = [];
  public static statementColumns = ['checkbox', 'payment', 'totalpricegross7','mwst7','totalpricegross19','mwst19'];
  public static evaluationsColumns = [ 'action','name', 'description'];
  public static couponsColumns = [ 'action','description', 'couponcode', 'percentage', 'minimumValue','type','status', 'validFrom','validTo'];
  public static qrcodesColumns =  [ 'action','checkbox','qrcode','tablenumber',   'qrcodeimage'];
  public static notificationColumns =  ['action','messagedate','messagetime', 'title', 'status','message'];

  public static updateLogsOrdersColumns = ['ordernumberHeader','invoiceNumberHeader','invoiceStatusHeader','updatesHeader','orderHeader','deliveryDateHeader','deliveryMethodHeader','paymentmethodHeader','nameHeader','branchHeader','deliveryaddressHeader','billingaddressHeader','billingNameHeader','productnameHeader','singlepriceHeader','numberHeader','netpriceHeader','vatrateHeader','totalHeader','statusHeader'];
  
  public static  updateLogsProductColumns = ['productIdHeader','updateDateTimeHeader','nameHeader','categoryNamesHeader','supplierNameHeader','costHeader','vatHeader','pricepickupHeader','pricedeliveryHeader','priceactionHeader','promotionstartdateHeader','promotionenddateHeader','daysHeader','isbundleHeader','bundlepickuppriceHeader','bundledeliverypriceHeader','bundleproductlistHeader'];

  public static updateLogsMemberColumns = ['memberIdHeader','updateDateTimeHeader','nameHeader','addressHeader','phoneHeader','emailHeader'];

  //  orderplacedtimeHeader, 'deliverydatetimeHeader','deliverytypeHeader'
}

export class ValueConstant {
  public static defaultPageSizeOptions: any = [50,100,150];
  public static defaultPageSize = 50;
  // public static defaultPageSize =  localStorage.getItem('pageSize');
  public static resultsLength = 0;
}

export class CheckBoxConstant {
  public static select = 'select';
  public static deselect = 'deselect';
  public static all = 'all'
}
export class ImgUrl{
  public static back_arrow ="../../../../../assets/images/icons/back-arrow.svg";
  public static delete ="../../../../../assets/images/icons/delete.svg";
  public static placeholder ='../../../../../assets/images/placeholder.png';
  public static edit ="../../../../../assets/images/icons/edit.svg";
}

export class PaginatorPerPageLabel{
  public static itemsPerPage= 'pages.common.buttons.itemsPerPage';
  public static firstPage= 'pages.common.buttons.firstPage';
  public static lastPage= 'pages.common.buttons.lastPage';
  public static nextPage= 'pages.common.buttons.nextPage';
  public static previousPage= 'pages.common.buttons.previousPage';
  public static of = 'pages.common.buttons.of';

}

export class UserRoles{
  public static SuperAdmin = 'SuperAdmin';
}

export class Label{
  public static CategoryName= 'CategoryName';
  public static Lieferung= 'Lieferung';
  public static Abholung = 'Abholung';
}

export class ButtonConstant{
  public static add = 'pages.common.buttons.add'
  public static search ='pages.common.buttons.search';
  public static save = 'pages.common.buttons.save';
  public static cancel ='pages.common.buttons.cancel'
  public static update = 'pages.common.buttons.update';
  public static delete = 'pages.common.buttons.delete';
  public static download = 'pages.orders.orderDetail.labels.download';
  public static branch_add = 'pages.branches.branchDetail.buttons.add';
  public static bundleproduct_add = 'pages.common.buttons.addProductBundle'
  public static sendmail = 'pages.orders.orderDetail.labels.sendmail'
  public static Cancelled = 'pages.orders.orderDetail.labels.Cancelled'
  public static close ='pages.common.buttons.close'
  public static ImageUpload='pages.common.buttons.ImageUpload'
  public static downloadqr='pages.qrcode.qrcodeDetail.input.downloadqr'
   public static createqr='pages.qrcode.qrcodeDetail.input.createqr'
}

export class currentLanguage{
  public static en ='en';
  public static de = 'de';
  public static in = 'in'
}
