<mat-card class="employee-table-wrapper bg-transparent">
  <mat-card-title class="employee-table-wrapper__header">
    <p class="employee-table-wrapper__title">{{'pages.branches.heading' | translate}}</p>
    <div class="d-flex">
      <!-- <div class="table-search">
        <input (keyup)="applyFilter($event)" #input class="form-control"
          placeholder="{{buttonConstant.search | translate}}">
      </div> -->
      <div>
        <!-- <button class="outline-button" mat-stroked-button> <img src="../../../../../assets/images/icons/product-filter.svg" alt=""> {{'pages.common.buttons.sortBy' | translate}} </button> -->
        <button class="fill-button" mat-flat-button [routerLink]="navigation.ADD_BRANCHES"> +
          {{buttonConstant.add | translate}} </button>
      </div>
    </div>
  </mat-card-title>


  <mat-card-content class="employee-table__content scrollable-table">
    <form [formGroup]="form" class="example-form h-100">
      <app-spinner *ngIf="showLoader" class="custom-loader h-100"></app-spinner>

   <div matSort  class="employee-table__table" #table>   
    <table mat-table  *ngIf="!showLoader" [dataSource]="dataSource">

            <!-- branch Column -->
        <ng-container matColumnDef="name" > 
          <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> 
            
            <div>
              <span>
                {{'pages.branches.branchTable.branchName' | translate}} </span>
                 <ng-container matColumnDef="nameHeader">
                  <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="name"/>
                  </ng-container>
            </div> 
          </th>
          <td class="employee-table__table-body" mat-cell *matCellDef="let element">
            <span class="employee-table__title">{{element.name !=null ? (element.name !=''?element.name:'-') : '-' }}</span>
          </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
          <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
            
             <div>
              <span>
                {{'pages.branches.branchTable.address' | translate}}</span>
                 <ng-container matColumnDef="addressHeader">
                  <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="address"/>
                  </ng-container>
            </div> 
            </th>
          <td class="employee-table__table-body" mat-cell *matCellDef="let element">
            <span class="nextline-span">{{element.address !=null ? (element.address !=''?element.address:'-') : '-'}}</span>
          </td>
        </ng-container>

         <!-- live Column -->
         <ng-container matColumnDef="online">
          <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef mat-sort-header>
             <div>
              <span>
                {{ 'pages.common.statusEnum.goLiveStatus' | translate }} </span>
                 <ng-container matColumnDef="onlineHeader">
                  <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="online"/>
                  </ng-container>
            </div> 
            </th>
          <td class="employee-table__table-body" mat-cell *matCellDef="let element">
            <div class="employee-table__content-badge btn-open" [ngClass]="element.online ? 'active': 'inactive'">
              <span *ngIf="element.online">{{ 'pages.common.statusEnum.onLine' | translate }}</span>
              <span *ngIf="!element.online">{{ 'pages.common.statusEnum.offLine' | translate }}</span>
            </div>
          </td>
        </ng-container>

        <!-- phone Column -->
        <ng-container matColumnDef="phone">
          <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> 
          
            <div>
              <span>
                {{'pages.branches.branchTable.phone' | translate}}</span>
                 <ng-container matColumnDef="phoneHeader">
                  <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="phone"/>
                  </ng-container>
            </div> 
           </th>
          <td class="employee-table__table-body" mat-cell *matCellDef="let element"> {{ element.phone !=null ? (element.phone !=''?getFirstEmail(element.phone) : '-' ) : '-'  }} </td>
        </ng-container>

        <!-- Arrow Column -->
        <!-- Arrow Column -->
        <ng-container matColumnDef="action">
          <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef> </th>
          <td class="employee-table__table-body" mat-cell *matCellDef="let element" [routerLink]="navigation.ADD_BRANCHES + '/' + element.uid " routerLinkActive="active" class="add-pointer"> <mat-icon>arrow_forward_ios</mat-icon> </td>
        </ng-container>

        <!-- Header search -->
        <!-- <form [formGroup]="form" class="example-form">
          <ng-container matColumnDef="nameHeader">
            <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef>
              <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="name"/>
            </th>
          </ng-container>
          <ng-container matColumnDef="addressHeader">
            <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef>
              <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="address"/>
            </th>
          </ng-container>
          <ng-container matColumnDef="onlineHeader">
            <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef>
              <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="online"/>
            </th>
          </ng-container>
          <ng-container matColumnDef="phoneHeader">
            <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef>
              <input class="form-control" placeholder="{{buttonConstant.search | translate}}" formControlName="phone" />
            </th>
          </ng-container>
          <ng-container matColumnDef="actionHeader">
            <th class="employee-table__table-header" mat-header-cell *matHeaderCellDef></th>
          </ng-container>
        </form> -->
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <!-- <tr mat-header-row *matHeaderRowDef="displayedHeaderColumns; sticky: true"></tr> -->
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
     </table>
   </div> 
    </form>
    </mat-card-content>
    <div class="pagination">
      <mat-paginator (page)="onPage($event,1)" [length]="resultsLength" [pageSize]="pageSize"
        [pageSizeOptions]="defaultPageSizeOptions" showFirstLastButtons></mat-paginator>

        <button class="fill-button" mat-flat-button (click)="onPage($event,2)"> {{'pages.orders.orderTable.selectAll' | translate}}</button>

    </div>

