import { Injectable } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { LeavePageComponent } from './leave-page/leave-page.component';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeavePageService {
  ReturnValue:boolean;
  constructor(private dialog: MatDialog) { }

  leavePageConfirmation() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(LeavePageComponent, dialogConfig);
  }

  confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || 'Are you sure?');
    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.data = {dialogTitle: 'title', dialogText: 'text'}
    // this.dialog.open(LeavePageComponent, dialogConfig);

    // const dialogRef = this.dialog.open(LeavePageComponent, {
    //   autoFocus: true,
    //   disableClose: true,
    //   data: { pageValue: 'call' }
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed', result);
    //   this.ReturnValue = result.data;
    //   return of(result);
    // });
    // console.log(dialogRef)
    return of(confirmation);
    // return of(this.ReturnValue);
  };
}
