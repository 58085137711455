import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BranchPageComponent } from '../branches/containers/branch-page/branch-page.component';
import { BranchDetailComponent, BranchDeliveryAreaComponent } from '../branches/components';
import { CanDeactivateGuard } from '../auth/guards/can-deactivate-guard.service';

const routes: Routes = [
  {
    path: '',
    component: BranchPageComponent,
  },
  {
    path: 'add',
    component: BranchDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { breadcrumb: 'Branch Detail'},
  },
  {
    path: 'add/:id',
    component: BranchDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { breadcrumb: 'Branch Detail'},
  },
  {
    path: 'detail/delivery-area',
    component: BranchDeliveryAreaComponent,
    data: { breadcrumb: 'Delivery Area'}
  },
  {
    path: 'detail/delivery-area/:id',
    component: BranchDeliveryAreaComponent,
    data: { breadcrumb: 'Delivery Area'}
  }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BranchesRoutingModule { }
