<!-- <breadcrumb #parent>
    <span class="breadcrumb" *ngFor="let route of parent.breadcrumbs">
        <a mat-button *ngIf="!route.terminal" href="" [routerLink]="[route.url]">{{ route.displayName }} / </a>
        <a mat-button *ngIf="route.terminal">{{ route.displayName }}</a>
    </span>
</breadcrumb> -->

<mat-toolbar class="page-header" role="heading">
    <mat-card class="breadcrumb-card width100 remove-box-shadow bg-transparent">
      <mat-card-content>
          <div class="breadcrumb-list">
              <div class="breadcrumb-list-item" *ngFor="let item of breadcrumbRoute" routerLink="{{'/'+item.route}}">
                  <div class="mat-list-item-content">
                      <div class="mat-ripple mat-list-item-ripple"></div>
                      <div class="mat-list-text"></div><span>{{item.title | titlecase}}</span>
                  </div>
              </div>
              <!-- <div class="breadcrumb-list-item">
                  <div class="mat-list-item-content">
                      <div class="mat-ripple mat-list-item-ripple"></div>
                      <div class="mat-list-text"></div><span>Company Name</span>
                  </div>
              </div> -->
              <!-- <div class="breadcrumb-list-item">
                  <div class="mat-list-item-content">
                      <div class="mat-ripple mat-list-item-ripple"></div>
                      <div class="mat-list-text"></div><span>last Company Name</span>
                  </div>
              </div> -->
          </div>
          <!-- <div class="date-time">Geplante Zustellung: <span>27.04.2021 18:00</span></div> -->
      </mat-card-content>
    </mat-card>
</mat-toolbar>
