import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'year'
})
export class YearPipe extends DatePipe implements PipeTransform {
  transform(value: Date|string|number): string|null;
  transform(value: null|undefined): null;
  transform(value: Date|string|number|null|undefined): string|null;
  transform(value: Date|string|number|null|undefined): string|null {
    return super.transform(value, 'y');
  }
}
