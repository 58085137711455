<form class="form" [formGroup]="form" (ngSubmit)="login()">
  <!-- <mat-form-field class="form__input">
    <input matInput placeholder="{{'pages.login.input.email' | translate}}"  type="email" formControlName="email">
  </mat-form-field> -->

  <!-- <mat-form-field class="form-group position-relative align-div">
    <input matInput placeholder="{{'pages.login.input.password' | translate}}" type="password" formControlName="password">
  </mat-form-field> -->
  <div class="logindiv">
    <div class="form-group position-relative align-div">
      <input type="text" placeholder="{{'pages.login.input.email' | translate}}" class="form-control" type="email"
        formControlName="email">
        <div
        *ngIf="form.controls['email']?.errors && (form.controls['email'].dirty || form.controls['email'].touched)"
        class="invalid-feed">
        <div *ngIf="form.controls['email'].errors.required">
          {{'pages.login.inputValidation.email.required' | translate}}</div>
        <div *ngIf="form.controls['email'].errors.email">
          {{'pages.login.inputValidation.email.valid' | translate}}</div>
        <div *ngIf="form.controls['email'].errors.invalid ">
          {{'pages.login.inputValidation.email.valid' | translate}}
        </div>
      </div>
    </div>
    <div class="form-group position-relative align-div">
      <input type="text" class="form-control" placeholder="{{'pages.login.input.password' | translate}}" type="password"
        formControlName="password">
        <div
        *ngIf="form.controls['password']?.errors && (form.controls['password'].dirty || form.controls['password'].touched)"
        class="invalid-feed">
        <div *ngIf="form.controls['password'].errors.required">
          {{'pages.login.inputValidation.password.required' | translate}}</div>
      </div>
    </div>
    <div class="form-actions">
      <!-- <button class="form-actions__forget ps-0" type="button" mat-button>{{'pages.login.buttons.forgot' |
        translate}}</button> -->
      <button class="form-actions__login px-5" mat-raised-button color="primary"
        type="submit">{{'pages.login.buttons.login' | translate}}</button>
    </div>
  </div>
</form>