import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import { AgmCoreModule } from '@agm/core';
import { MatSortModule } from '@angular/material/sort';
import { BranchesRoutingModule } from './branches-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { BranchDeliveryAreaComponent,BranchTableComponent, BranchDetailComponent } from './components';
import { BranchPageComponent } from '../branches/containers/branch-page/branch-page.component';
import { BranchService } from '../branches/services/branch.service';
import { TimeSlotDialogComponent } from './components/time-slot-dialog/time-slot-dialog.component';
import {BreadcrumbModule} from 'angular-crumbs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";


// localization module import
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DeliveryTypeDialogComponent } from './components/delivery-type-dialog/delivery-type-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [BranchDetailComponent, BranchDeliveryAreaComponent, BranchPageComponent, BranchTableComponent, TimeSlotDialogComponent, DeliveryTypeDialogComponent],
  imports: [
    CommonModule,
    BranchesRoutingModule,
    SharedModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTabsModule,
    MatSortModule,
    AgmCoreModule.forRoot({
     // apiKey: 'AIzaSyDq2kA0Fm4jAn5Di8nBu6Wtkp6JR7wtE0Y',
     apiKey: 'AIzaSyDjAv5-53CN-G9xnpqqwM9IGLqxgX4J2Fw',
      libraries: ['places', 'drawing', 'geometry'],
    }),
    BreadcrumbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMatTimepickerModule.setLocale("en-US")
  ],
  providers: [
    BranchService,
    TranslateService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  exports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxMatTimepickerModule
  ]
})
export class BranchesModule { }

// loader module
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

