<p class="heading">
    <strong *ngIf="id">{{'pages.branches.deliveryTypeDialog.editHeading' | translate}}</strong>
     <strong *ngIf="!id">{{'pages.branches.deliveryTypeDialog.addHeading' | translate}}</strong><mat-icon mat-dialog-close>close</mat-icon></p>
     <p class="heading redcolor">
     <strong *ngIf="selectedTabindex == 1">{{'pages.branches.deliveryTypeDialog.addHeading1' | translate}}</strong>
</p>
<mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedTab" (selectedTabChange)="tabChanged($event)">
    <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.bundled' | translate}}" [disabled]="false">
        <form [formGroup]="bundledForm"  (ngSubmit)="submitForm()">
            <p class="topAndmargin">{{'pages.branches.deliveryTypeDialog.input.deliverOn' | translate}}</p>
            <mat-tab-group mat-align-tabs="center">
                <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.mon' | translate}}" [disabled]="weekdays">
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.by' | translate}}</label>
                            <input type="text" class="form-control" formControlName="mondayby" (ngModelChange)="condition('mondayby', 'mondaycutoff', 'mondaymaximumorders')" format='24' [ngxMatTimepicker]="mondayby" readonly>
                            <ngx-mat-timepicker #mondayby minutesGap="5">
                            </ngx-mat-timepicker>
                            <div *ngIf="bundledForm.controls['mondayby']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['mondayby'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.by.required' | translate}}</div>
                               
                            </div>
                        </div>
                        <div class="col">                          
                                <label>{{'pages.branches.deliveryTypeDialog.input.orderCutOff' | translate}}</label>
                                <div class="cutoff-hours">
                                    <div>                                    
                                        <input type="number" formControlName="mondaycutoff" min="1" max="48" (ngModelChange)="condition('mondayby', 'mondaycutoff', 'mondaymaximumorders')" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 48 ? '' : this.value && this.value == 0?'':this.value" class="form-control">
                                        <div *ngIf="bundledForm.controls['mondaycutoff']?.errors" class="invalid-feedback">
                                            <div *ngIf="bundledForm.controls['mondaycutoff'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.orderCutOff.required' | translate}}</div>                                        
                                        </div>
                                    </div>
                                    <div>
                                        <label>{{'pages.branches.deliveryTypeDialog.input.hoursBefore' | translate}}</label>  
                                    </div>
                                </div>                          
                        </div>
                    </div>
                    <div class="row topAndmargin" >
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                            <input type="number" class="form-control" formControlName="mondaymaximumorders" (ngModelChange)="condition('mondayby', 'mondaycutoff', 'mondaymaximumorders')" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value">
                            <div *ngIf="bundledForm.controls['mondaymaximumorders']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['mondaymaximumorders'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>
                               
                            </div>
                           
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.tue' | translate}}" [disabled]="weekdays">
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.by' | translate}}</label>
                            <input type="text" formControlName="tuesdayby" class="form-control" (ngModelChange)="condition('tuesdayby', 'tuesdaycutoff', 'tuesdaymaximumorders')" format='24' [ngxMatTimepicker]="tuesdayby" readonly>
                            <ngx-mat-timepicker #tuesdayby minutesGap="5"></ngx-mat-timepicker>
                            <div *ngIf="bundledForm.controls['tuesdayby']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['tuesdayby'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.by.required' | translate}}</div>
                            
                            </div>
                            
                        </div>
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.orderCutOff' | translate}}</label>
                            <div class="cutoff-hours">
                                <div>
                                    <input type="number" formControlName="tuesdaycutoff" min="1" max="48" (ngModelChange)="condition('tuesdayby', 'tuesdaycutoff', 'tuesdaymaximumorders')" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 48 ? '' : this.value && this.value == 0?'':this.value" class="form-control" >
                                    <div *ngIf="bundledForm.controls['tuesdaycutoff']?.errors" class="invalid-feedback">
                                        <div *ngIf="bundledForm.controls['tuesdaycutoff'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.orderCutOff.required' | translate}}</div>
                                    
                                    </div>
                                </div>
                                <div>
                                    <label>{{'pages.branches.deliveryTypeDialog.input.hoursBefore' | translate}}</label>  
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                            <input type="number" class="form-control" formControlName="tuesdaymaximumorders" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value" (ngModelChange)="condition('tuesdayby', 'tuesdaycutoff', 'tuesdaymaximumorders')">
                            <div class="invalid-feedback" *ngIf="bundledForm.controls['tuesdaymaximumorders']?.errors">
                                <div *ngIf="bundledForm.controls['tuesdaymaximumorders'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.wed' | translate}}" [disabled]="weekdays">
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.by' | translate}}</label>
                            <input type="text" formControlName="wednesdayby" class="form-control" (ngModelChange)="condition('wednesdayby', 'wednesdaycutoff', 'wednesdaymaximumorders')" format='24' [ngxMatTimepicker]="wednesdayby" readonly>
                            <ngx-mat-timepicker #wednesdayby minutesGap="5"></ngx-mat-timepicker>
                            <div *ngIf="bundledForm.controls['wednesdayby']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['wednesdayby'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.by.required' | translate}}</div>
                            
                            </div>
                            
                        </div>
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.orderCutOff' | translate}}</label>
                            <div class="cutoff-hours">
                                <div>
                                    <input type="number" formControlName="wednesdaycutoff" (ngModelChange)="condition('wednesdayby', 'wednesdaycutoff', 'wednesdaymaximumorders')" min="1" max="48" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 48 ? '' : this.value && this.value == 0?'':this.value" class="form-control" >
                                    <div *ngIf="bundledForm.controls['wednesdaycutoff']?.errors" class="invalid-feedback">
                                        <div *ngIf="bundledForm.controls['wednesdaycutoff'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.orderCutOff.required' | translate}}</div>
                                    
                                    </div>
                                </div>
                                <div>
                                    <label>{{'pages.branches.deliveryTypeDialog.input.hoursBefore' | translate}}</label> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row topAndmargin" >
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                            <input type="number" class="form-control" formControlName="wednesdaymaximumorders" (ngModelChange)="condition('wednesdayby', 'wednesdaycutoff', 'wednesdaymaximumorders')" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value">
                            <div class="invalid-feedback" *ngIf="bundledForm.controls['wednesdaymaximumorders']?.errors">
                                <div *ngIf="bundledForm.controls['wednesdaymaximumorders'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>
                                
                            </div>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.thu' | translate}}" [disabled]="weekdays">
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.by' | translate}}</label>
                            <input type="text" formControlName="thursdayby" class="form-control" (ngModelChange)="condition('thursdayby', 'thursdaycutoff', 'thursdaymaximumorders')" format='24' [ngxMatTimepicker]="thursdayby" readonly>
                            <ngx-mat-timepicker #thursdayby minutesGap="5"></ngx-mat-timepicker>
                            <div *ngIf="bundledForm.controls['thursdayby']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['thursdayby'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.by.required' | translate}}</div>
                            
                            </div>                            
                        </div>
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.orderCutOff' | translate}}</label>
                            <div class="cutoff-hours">
                                <div>
                                    <input type="number" formControlName="thursdaycutoff" (ngModelChange)="condition('thursdayby', 'thursdaycutoff', 'thursdaymaximumorders')" min="1" max="48" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 48 ? '' : this.value && this.value == 0?'':this.value" class="form-control">
                                    <div *ngIf="bundledForm.controls['thursdaycutoff']?.errors" class="invalid-feedback">
                                        <div *ngIf="bundledForm.controls['thursdaycutoff'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.orderCutOff.required' | translate}}</div>
                                    
                                    </div>
                                </div>
                                <div>
                                    <label>{{'pages.branches.deliveryTypeDialog.input.hoursBefore' | translate}}</label> 
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row topAndmargin" >
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                            <input type="number" class="form-control" formControlName="thursdaymaximumorders" (ngModelChange)="condition('thursdayby', 'thursdaycutoff', 'thursdaymaximumorders')" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value">
                            <div class="invalid-feedback" *ngIf="bundledForm.controls['thursdaymaximumorders']?.errors">
                                <div *ngIf="bundledForm.controls['thursdaymaximumorders'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.fri' | translate}}" [disabled]="weekdays">
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.by' | translate}}</label>
                            <input type="text" formControlName="fridayby" class="form-control" (ngModelChange)="condition('fridayby', 'fridaycutoff', 'fridaymaximumorders')" format='24' [ngxMatTimepicker]="fridayby" readonly>
                            <ngx-mat-timepicker #fridayby minutesGap="5"></ngx-mat-timepicker>
                            <div *ngIf="bundledForm.controls['fridayby']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['fridayby'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.by.required' | translate}}</div>
                            
                            </div>
                            
                        </div>
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.orderCutOff' | translate}}</label>
                            <div class="cutoff-hours">
                                <div>
                                    <input type="number" formControlName="fridaycutoff" (ngModelChange)="condition('fridayby', 'fridaycutoff', 'fridaymaximumorders')" min="1" max="48" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 48 ? '' : this.value && this.value == 0?'':this.value" class="form-control" >
                                    <div *ngIf="bundledForm.controls['fridaycutoff']?.errors" class="invalid-feedback">
                                        <div *ngIf="bundledForm.controls['fridaycutoff'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.orderCutOff.required' | translate}}</div>
                                    
                                    </div>
                                </div>
                                <div>
                                    <label>{{'pages.branches.deliveryTypeDialog.input.hoursBefore' | translate}}</label>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row topAndmargin" >
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                            <input type="number" class="form-control" formControlName="fridaymaximumorders" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value">
                            <div class="invalid-feedback" *ngIf="bundledForm.controls['fridaymaximumorders']?.errors">
                                <div *ngIf="bundledForm.controls['fridaymaximumorders'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>                                
                            </div>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.sat' | translate}}" [disabled]="weekdays">
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.by' | translate}}</label>
                            <input type="text" formControlName="saturdayby" class="form-control" (ngModelChange)="condition('saturdayby', 'saturdaycutoff', 'saturdaymaximumorders')" format='24' [ngxMatTimepicker]="saturdayby" readonly>
                            <ngx-mat-timepicker #saturdayby minutesGap="5"></ngx-mat-timepicker>
                            <div *ngIf="bundledForm.controls['saturdayby']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['saturdayby'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.by.required' | translate}}</div>
                            
                            </div>                            
                        </div>
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.orderCutOff' | translate}}</label>
                            <div class="cutoff-hours">
                                <div>
                                    <input type="number" formControlName="saturdaycutoff" (ngModelChange)="condition('saturdayby', 'saturdaycutoff', 'saturdaymaximumorders')" min="1" max="48" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 48 ? '' : this.value && this.value == 0?'':this.value" class="form-control">
                                    <div *ngIf="bundledForm.controls['saturdaycutoff']?.errors" class="invalid-feedback">
                                        <div *ngIf="bundledForm.controls['saturdaycutoff'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.orderCutOff.required' | translate}}</div>
                                    
                                    </div>
                                </div>
                                <div>
                                    <label>{{'pages.branches.deliveryTypeDialog.input.hoursBefore' | translate}}</label>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row topAndmargin" >
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                            <input type="number" class="form-control" formControlName="saturdaymaximumorders" (ngModelChange)="condition('saturdayby', 'saturdaycutoff', 'saturdaymaximumorders')" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value">
                            <div class="invalid-feedback" *ngIf="bundledForm.controls['saturdaymaximumorders']?.errors">
                                <div *ngIf="bundledForm.controls['saturdaymaximumorders'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>
                            </div>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </mat-tab>
                <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.sun' | translate}}" [disabled]="weekdays">
                    <div class="row topAndmargin">
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.by' | translate}}</label>
                            <input type="text" formControlName="sundayby" class="form-control" (ngModelChange)="condition('sundayby', 'sundaycutoff', 'sundaymaximumorders')" format='24' [ngxMatTimepicker]="sundayby" readonly>
                            <ngx-mat-timepicker #sundayby minutesGap="5"></ngx-mat-timepicker>
                            <div *ngIf="bundledForm.controls['sundayby']?.errors" class="invalid-feedback">
                                <div *ngIf="bundledForm.controls['sundayby'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.by.required' | translate}}</div>
                            
                            </div>                            
                        </div>
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.orderCutOff' | translate}}</label>
                            <div class="cutoff-hours">
                                <div>
                                    <input type="number" formControlName="sundaycutoff" (ngModelChange)="condition('sundayby', 'sundaycutoff', 'sundaymaximumorders')" min="1" max="48" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 48 ? '' : this.value && this.value == 0?'':this.value" class="form-control">
                                    <div *ngIf="bundledForm.controls['sundaycutoff']?.errors" class="invalid-feedback">
                                        <div *ngIf="bundledForm.controls['sundaycutoff'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.orderCutOff.required' | translate}}</div>
                                    
                                    </div>
                                </div>
                                <div>
                                    <label>{{'pages.branches.deliveryTypeDialog.input.hoursBefore' | translate}}</label>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div class="row topAndmargin" >
                        <div class="col">
                            <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                            <input type="number" class="form-control" formControlName="sundaymaximumorders" (ngModelChange)="condition('sundayby', 'sundaycutoff', 'sundaymaximumorders')" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value">
                            <div class="invalid-feedback" *ngIf="bundledForm.controls['sundaymaximumorders']?.errors">
                                <div *ngIf="bundledForm.controls['sundaymaximumorders'].errors.required">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>
                                
                            </div>
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <button class="fill-button" mat-flat-button> {{'pages.common.buttons.save' | translate}} </button>
            <!-- <button class="outline-button" mat-stroked-button (click)="deleteDeliveryType(id)" *ngIf="id"> {{'pages.common.buttons.delete' | translate}} </button> -->
            <p>{{'pages.branches.deliveryTypeDialog.deliveryTypeNote' | translate}}</p>
        </form>
    </mat-tab>
    <mat-tab label="{{'pages.branches.deliveryTypeDialog.input.onDemand' | translate}}" [disabled]="false">
    <form [formGroup]="onDemandForm"  (ngSubmit)="submitForm('onDemandForm')">
        <div class="form-group position-relative" >
            <label class="form-label topAndmargin">{{'pages.branches.deliveryTypeDialog.input.slotInterval' | translate}}</label>
            <select class="form-control" formControlName="slotinterval">
                <option value="">{{'pages.branches.deliveryTypeDialog.input.selectInterval' | translate}}</option>
                <option value="5">5 min</option>
                <option value="10">10 min</option>
                <option value="15">15 min</option>
                <option value="20">20 min</option>
                <option value="25">25 min</option>
                <option value="30">30 min</option>
                <option value="35">35 min</option>
                <option value="40">40 min</option>
                <option value="45">45 min</option>
                <option value="50">50 min</option>
                <option value="55">55 min</option>
                <option value="60">60 min</option>
            </select>
            <span class="dd-arrow"></span>
            <div class="invalid-feedback">
                <div class="slotinterval" *ngIf="onDemandForm.controls['slotinterval'].invalid && (onDemandForm.controls['slotinterval'].dirty || onDemandForm.controls['slotinterval'].touched)">{{'pages.branches.deliveryTypeDialog.inputValidation.slotInterval.required' | translate}}</div>
            </div>
        </div>
        <div class="row topAndmargin">
            <div class="col">
                <label>{{'pages.branches.deliveryTypeDialog.input.maximumOrders' | translate}}</label>
                <input type="number" formControlName="maximumorders" min="1" max="100" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value > 100 ? '' : this.value && this.value == 0?'':this.value" class="form-control">                
                <div class="invalid-feedback" *ngIf="onDemandForm.controls['maximumorders'].invalid && (onDemandForm.controls['maximumorders'].dirty || onDemandForm.controls['maximumorders'].touched)">{{'pages.branches.deliveryTypeDialog.inputValidation.maximumOrders.required' | translate}}</div>
            </div>
            <div class="col">
                <label>{{'pages.branches.deliveryTypeDialog.input.ondemandcutoff' | translate}}</label>
                <div class="cutoff-hours">
                    <div>
                        <input type="number" formControlName="ondemandcutoff" min="1" max="120" oninput="this.value=this.value.replace(/[^0-9]/g,'') && this.value && this.value == 0?'':this.value" class="form-control">                
                        <div class="invalid-feedback" *ngIf="onDemandForm.controls['ondemandcutoff'].invalid && (onDemandForm.controls['ondemandcutoff'].dirty || onDemandForm.controls['ondemandcutoff'].touched)">{{'pages.branches.deliveryTypeDialog.inputValidation.ondemandcutoff.required' | translate}}</div>
                    </div>
                    <div>
                        <label>{{'pages.branches.deliveryTypeDialog.input.minutusBefore' | translate}}</label>
                    </div>
                </div>
                
            </div>
        </div>
        <button class="fill-button" mat-flat-button> {{'pages.common.buttons.save' | translate}} </button>
        <p>{{'pages.branches.deliveryTypeDialog.deliveryTypeNote' | translate}}</p>
    </form>
    </mat-tab>
</mat-tab-group>

