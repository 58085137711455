<app-layout>
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <mat-card class="map-box-outer remove-box-shadow bg-transparent">
    <mat-card-content>
      <div class="frm-header">
        <div>
          <span class="btn-back add-pointer" (click)="back()"><img src="{{imgUrl.back_arrow}}" alt=""></span>
          <h4>{{'pages.branches.branchDeliveryArea.heading' | translate}} / Area 1</h4>
        </div>
        <div>
          <button class="header-outline-button" mat-flat-button (click)="deleteSelectedShape()"
            [disabled]="selectedArea == 0 && this.areaID == undefined"> {{buttonConstant.cancel | translate}}
          </button>
          <button class="fill-button" mat-flat-button (click)="save()" [disabled]="selectedArea == 0 && !updateArea">
            {{buttonConstant.save | translate}} </button>
        </div>
      </div>
    </mat-card-content>

    <div class="frm-box-outer" *ngIf="show">
      <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" (mapReady)="onMapReady($event)" class="agm-map">
        <agm-polygon [paths]="paths" [draggable]="true" [editable]="true" [polyDraggable]="true"
          (polyPathsChange)="polytest($event)"></agm-polygon>
      </agm-map>
    </div>

  </mat-card>
</app-layout>
