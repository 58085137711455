import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';

import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthModule } from './pages/auth/auth.module';
import { BranchesModule } from './pages/branches/branches.module';
import { CoreModule } from './core/core.module';
import { DatePipe } from '@angular/common';
import { LeavePageComponent } from './pages/dialog/leave-page/leave-page.component';
import { DatePickerFormatDirective } from "./date-picker-format.directive";
import { TranslationPipe } from './translation.pipe';

// import { MatProgressSpinnerModule } from '@angular/material';

// import { OrderUpdateLogsComponent } from './pages/order-update-logs/order-update-logs.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LeavePageComponent,
    DatePickerFormatDirective,
    TranslationPipe,
    // OrderUpdateLogsComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AuthModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot({timeOut: 1200, positionClass: 'toast-top-right', preventDuplicates: true} ),
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    BranchesModule,
    CoreModule,
    MatTabsModule,
    
  ],

  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: navigator.language },   // 'de-DE'
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
