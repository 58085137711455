import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Branch } from '../models/branch';
import { map} from 'rxjs/operators';
import { ApiService } from '../../../core';


@Injectable({
  providedIn: 'root'
})
export class BranchService {
  alternatedelivery: any="";
  alternatepickup: any="";
  standarddelivery: any="";
  standardpickup: any="";
  publicholidaysdelivery: any="";
  publicholidayspickup: any="";

  deliveryAreaID = '';
  deliverytypeid = "";
  timeSlotId;
  deliveryAddressGeometry:any;
  formData: any;
  pageLength:number;  // set page length
  constructor(private apiService: ApiService) { }

  getBranches(): Observable<Branch[]> {
    return this.apiService.get('Branch')
      .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  getBranchById(id:number): Observable<Branch[]> {
    return this.apiService.get('Branch/'+id)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }
  getProduct(): Observable<any[]> {
    return this.apiService.get('Product')
      .pipe(map((data) => { 
        return data; 
      },
      err => { 
        return err;
      }
    ));  
  }
  addDeliveryArea(dataUpdate): Observable<Branch[]> {
    return this.apiService.post('BranchDeliveryAreas', dataUpdate)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  getDeliveryAreaById(id:number): Observable<Branch[]> {
    return this.apiService.get('BranchDeliveryAreas/'+id)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  updateDeliveryArea(dataUpdate): Observable<Branch[]> {
    return this.apiService.put('BranchDeliveryAreas', dataUpdate)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  updateBranch(dataUpdate): Observable<Branch[]> {
    return this.apiService.put('Branch', dataUpdate)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  addBranch(dataUpdate): Observable<Branch[]> {
    return this.apiService.post('Branch', dataUpdate)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  deleteBranch(id:number): Observable<Branch[]> {
    return this.apiService.delete('Branch?Id='+id)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  //******  Time slot services start ********//
  listTimeSlotes() {
    return this.apiService.get('TimeSlot')
      .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  getTimeSlote(id:number) {
    return this.apiService.get('TimeSlot/'+id)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }


  updateTimeSlote(dataUpdate) {
    return this.apiService.put('TimeSlot', dataUpdate)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  addTimeSlote(dataUpdate) {
    return this.apiService.post('TimeSlot', dataUpdate)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

  deleteTimeSlote(id:number) {
    return this.apiService.delete('TimeSlot?Id='+id)
     .pipe(map((data) => {
        return data;
      },
      err => {
        return err;
      }
    ));
  }

// **** delivery type service start *******
getDeliveryType(id:number) {
  return this.apiService.get('DeliveryType/'+id)
   .pipe(map((data) => {
      return data;
    },
    err => {
      return err;
    }
  ));
}


updateDeliveryType(dataUpdate) {
  return this.apiService.put('DeliveryType', dataUpdate)
   .pipe(map((data) => {
      return data;
    },
    err => {
      return err;
    }
  ));
}

addDeliveryType(dataUpdate) {
  return this.apiService.post('DeliveryType', dataUpdate)
   .pipe(map((data) => {
      return data;
    },
    err => {
      return err;
    }
  ));
}

deleteDeliveryType(id:number) {
  return this.apiService.delete('DeliveryType?Id='+id)
   .pipe(map((data) => {
      return data;
    },
    err => {
      return err;
    }
  ));
}

getAllProductsForDropdown(): Observable<any[]> {
  return this.apiService.get('Product/GetProductList')
    .pipe(map((data) => { 
      return data; 
    },
    err => { 
      return err;
    }
  ));  
}

goLiveBranchCheck(id:number): Observable<Branch[]> {
  let query='Branch/CanGoLive';
  if(id)
    query=`Branch/CanGoLive?id=${id}`
    
  return this.apiService.get(query)
   .pipe(map((data) => {
      return data;
    },
    err => {
      return err;
    }
  ));
}

}
