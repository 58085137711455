import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BranchService } from '../../services/branch.service';
import { NotificationService } from '../../../../core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  BranchDetailsMessage,
  OrdersMessages,
  productMessage,
} from 'src/app/pages/constants';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-time-slot-dialog',
  templateUrl: './time-slot-dialog.component.html',
  styleUrls: ['./time-slot-dialog.component.scss'],
})
export class TimeSlotDialogComponent {
  timeSlotForm: FormGroup;
  timeSlotHolidayForm: FormGroup;
  id: number;
  branchId: number;
  weekdays: boolean;
  dialogType: string;
  publicholidaysdelivery: any;
  publicholidayspickup: any;
  publicHolidayId: number;
  isstandardpickup: boolean;
  isalternatepickup: boolean;
  isstandarddelivery: boolean;
  isalternatedelivery: boolean;
  public today = new Date();
  public PublicHolidayArr: any;
  public tempArr: any = [];
  public deleteHolidayArray: any;
  public deleteHolidayPickupArray: any;
  public showHideTime:boolean;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TimeSlotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private branchService: BranchService,
    private notifyService: NotificationService,
    private translate: TranslateService
  ) {
    this.showHideTime = data.data.closeDay
    this.branchId = this.data.branchId;
    this.id = this.data.slotId;
    this.dialogType = this.data.dialogType;
    this.publicHolidayId = this.data.publicHolidayId;
    this.isstandardpickup = this.data.isstandardpickup;
    (this.isalternatepickup = this.data.isalternatepickup),
      (this.isstandarddelivery = this.data.isstandarddelivery),
      (this.isalternatedelivery = this.data.isalternatedelivery),
      this.getTimeSlote(this.id);
  }

  // ------------------------------------------------------------------------------------------------
  // @ Set form group key and if edit category - get detail by id
  // ------------------------------------------------------------------------------------------------
  ngOnInit() {
    this.timeSlotHoliday();
    this.initializeTimeslotForm();
  }
  public initializeTimeslotForm() {
    this.timeSlotForm = this.fb.group({
      uid: [''],
      title: ['', Validators.required],
      mondayfrom: [''],
      mondayTo: [''],
      tuesdayfrom: [''],
      tuesdayTo: [''],
      wednesdayfrom: [''],
      wednesdayTo: [''],
      thursdayfrom: [''],
      thursdayTo: [''],
      fridayfrom: [''],
      fridayTo: [''],
      saturdayfrom: [''],
      saturdayTo: [''],
      sundayfrom: [''],
      sundayTo: [''],
    });
  }

  public timeSlotHoliday() {
    this.timeSlotHolidayForm = this.fb.group({
      uid: [''],
      title: ['', Validators.required],
      fromTime: [''],
      toTime: [''],
      closeDay: [false],
      date: ['', Validators.required],
    });
  }

  checkCheckBoxvalue(event){
    this.showHideTime =event.checked
    // if(event.checked){
    //   this.timeSlotHolidayForm.controls['fromTime'].setValue('');
    //   this.timeSlotHolidayForm.controls['toTime'].setValue('');
    // }
  }

  condition(field1: any, field2: any) {
    // check if one field is required secound should be required
    if (!this.timeSlotForm.value[field1] && !this.timeSlotForm.value[field2]) {
      // if both null true
      this.timeSlotForm.get([field1])?.setErrors(null);
      this.timeSlotForm.get([field2])?.setErrors(null);
      this.weekdays = false;
    } else if (
      this.timeSlotForm.value[field1] &&
      this.timeSlotForm.value[field2]
    ) {
      // if both not null true
      this.timeSlotForm.get([field1]).setErrors(null);
      this.timeSlotForm.get([field2]).setErrors(null);
      var valid = this.dateValidator(
        this.timeSlotForm.value[field1],
        this.timeSlotForm.value[field2]
      );
      if (!valid) {
        // set custom error
        this.timeSlotForm.get([field2]).setErrors({
          invalid: this.translate.instant(OrdersMessages.to_invalid),
        });
        this.weekdays = true;
        return;
      }
      this.weekdays = false;
    } else if (
      this.timeSlotForm.value[field1] &&
      !this.timeSlotForm.value[field2]
    ) {
      // if second null
      this.timeSlotForm.get([field2]).setValidators([Validators.required]);
      this.timeSlotForm.get([field2]).updateValueAndValidity();
      this.weekdays = true;
    } else if (
      !this.timeSlotForm.value[field1] &&
      this.timeSlotForm.value[field2]
    ) {
      // if first null
      this.timeSlotForm.get([field1]).setValidators([Validators.required]);
      this.timeSlotForm.get([field1]).updateValueAndValidity();
      this.weekdays = true;
    } else {
      //both validate true
      this.timeSlotForm.get([field1]).setErrors(null);
      this.timeSlotForm.get([field2]).setErrors(null);
      this.weekdays = true;
    }
    //***** weekdays days true ? disable else enable mat-tab */
  }

  conditionForHoliday(field1: any, field2: any) {
    // check if one field is required secound should be required
    if (
      !this.timeSlotHolidayForm.value[field1] &&
      !this.timeSlotHolidayForm.value[field2]
    ) {
      // if both null true
      this.timeSlotHolidayForm.get([field1])?.setErrors(null);
      this.timeSlotHolidayForm.get([field2])?.setErrors(null);
      this.weekdays = false;
    } else if (
      this.timeSlotHolidayForm.value[field1] &&
      this.timeSlotHolidayForm.value[field2]
    ) {
      // if both not null true
      this.timeSlotHolidayForm.get([field1]).setErrors(null);
      this.timeSlotHolidayForm.get([field2]).setErrors(null);
      var valid = this.dateValidator(
        this.timeSlotHolidayForm.value[field1],
        this.timeSlotHolidayForm.value[field2]
      );
      if (!valid) {
        // set custom error
        this.timeSlotHolidayForm.get([field2]).setErrors({
          invalid: this.translate.instant(OrdersMessages.to_invalid),
        });
        this.weekdays = true;
        return;
      }
      this.weekdays = false;
    } else if (
      this.timeSlotHolidayForm.value[field1] &&
      !this.timeSlotHolidayForm.value[field2]
    ) {
      // if second null
      this.timeSlotHolidayForm
        .get([field2])
        .setValidators([Validators.required]);
      this.timeSlotHolidayForm.get([field2]).updateValueAndValidity();
      this.weekdays = true;
    } else if (
      !this.timeSlotHolidayForm.value[field1] &&
      this.timeSlotHolidayForm.value[field2]
    ) {
      // if first null
      this.timeSlotHolidayForm
        .get([field1])
        ?.setValidators([Validators.required]);
      this.timeSlotHolidayForm.get([field1]).updateValueAndValidity();
      this.weekdays = true;
    } else {
      //both validate true
      this.timeSlotHolidayForm.get([field1]).setErrors(null);
      this.timeSlotHolidayForm.get([field2]).setErrors(null);
      this.weekdays = true;
    }
    //***** weekdays days true ? disable else enable mat-tab */
  }
  // ------------------------------------------------------------------------------------------------
  // @ If to_time should not greater then from_time
  // ------------------------------------------------------------------------------------------------
  dateValidator(fromTime, toTime) {
    console.log(fromTime,"fromTime")
    var startTime = moment(fromTime, 'HH:mm a');
    var endTime = moment(toTime, 'HH:mm a');
    if (endTime <= startTime) {
      return false;
    } else {
      return true;
    }
  }

  onNoClick(data=[],res_id = null): void {
    this.dialogRef.close({ data: data,uid: res_id });
  }

  // ------------------------------------------------------------------------------------------------
  // @ If this is update form, get user details and update form group
  // ------------------------------------------------------------------------------------------------

  public parsedata(val: any) {
    if (val) {
      return JSON.parse(val);
    }
    return '';
  }
  getTimeSlote(index: number) {
    if (index) {
      this.branchService.getTimeSlote(index).subscribe(
        (response: any) => {
          this.publicholidaysdelivery = response.publicholidaysdelivery
            ? response.publicholidaysdelivery
            : '';
          this.publicholidayspickup = response.publicholidayspickup
            ? response.publicholidayspickup
            : '';
          this.branchService.publicholidaysdelivery =
            this.publicholidaysdelivery;
          this.branchService.publicholidayspickup = this.publicholidayspickup;
          this.branchService.standarddelivery = response.standarddelivery;
          this.branchService.standardpickup = response.standardpickup;
          this.branchService.alternatedelivery = response.alternatedelivery;
          this.branchService.alternatepickup = response.alternatepickup;
          this.deleteHolidayArray = this.parsedata(
            this.branchService.publicholidaysdelivery
          );
          this.deleteHolidayPickupArray = this.parsedata(
            this.branchService.publicholidayspickup
          );
          if (
            this.dialogType == 'openingTimesDeliveryStandard' &&
            response.standarddelivery
          ) {
            this.editOpeningTimesDeliveryPickup(
              JSON.parse(response.standarddelivery)
            ); // edit Opening Times Delivery-Pickup
          } else if (
            this.dialogType == 'openingTimesPickupStandard' &&
            response.standardpickup
          ) {
            this.editOpeningTimesDeliveryPickup(
              JSON.parse(response.standardpickup)
            ); // edit Opening Times Delivery-Pickup
          } else if (
            this.dialogType == 'openingTimesDeliveryAlternative' &&
            response.alternatedelivery
          ) {
            this.editOpeningTimesDeliveryPickup(
              JSON.parse(response.alternatedelivery)
            ); // edit Opening Times Delivery-Pickup
          } else if (
            this.dialogType == 'openingTimesPickupAlternative' &&
            response.alternatepickup
          ) {
            this.editOpeningTimesDeliveryPickup(
              JSON.parse(response.alternatepickup)
            ); // edit Opening Times Delivery-Pickup
          }

          if (this.publicHolidayId) {
            // if editable
            if (this.dialogType == 'openingTimesPublicHolidaysDelivery') {
              this.editPublicHoliday(
                JSON.parse(response.publicholidaysdelivery)
              );
            } else if (this.dialogType == 'openingTimesPublicHolidaysPickup') {
              this.editPublicHoliday(JSON.parse(response.publicholidayspickup));
            }
          }
        },
        (err) => {
          console.log(err);
          // this.errors = err;
        }
      );
    }
  }

  editOpeningTimesDeliveryPickup(res) {
    this.timeSlotForm.patchValue({
      title: res.title,
      mondayfrom: res.openings[0].from,
      mondayTo: res.openings[0].to,
      tuesdayfrom: res.openings[1].from,
      tuesdayTo: res.openings[1].to,
      wednesdayfrom: res.openings[2].from,
      wednesdayTo: res.openings[2].to,
      thursdayfrom: res.openings[3].from,
      thursdayTo: res.openings[3].to,
      fridayfrom: res.openings[4].from,
      fridayTo: res.openings[4].to,
      saturdayfrom: res.openings[5].from,
      saturdayTo: res.openings[5].to,
      sundayfrom: res.openings[6].from,
      sundayTo: res.openings[6].to,
    });
  }

  editPublicHoliday(res) {
    // show editable public holiday

    let index = this.publicHolidayId - 1;
    this.timeSlotHolidayForm.patchValue({
      uid: this.publicHolidayId,
      title: res[index].title,
      fromTime: res[index].from,
      toTime: res[index].to,
      date: res[index].date,
      closeDay: res[index].closeDay,
    });
  }

  // ------------------------------------------------------------------------------------------------
  // @ When submit detail append formdata values. And callaccording to condition (Add/Update).
  // ------------------------------------------------------------------------------------------------

  submitForm(value: string) {
    if( this.showHideTime){
      console.log(this.showHideTime)
      this.timeSlotHolidayForm.controls['fromTime'].setValue('');
      this.timeSlotHolidayForm.controls['toTime'].setValue('');
    }
    if (value == 'istimeSlotForm') {
      if (this.timeSlotForm.invalid) {
        this.timeSlotForm.markAllAsTouched();
        return;
      }
    } else {
      if (this.timeSlotHolidayForm.invalid) {
        this.timeSlotHolidayForm.markAllAsTouched();
        return;
      }
    }

    //  stop here if form is invalid

    // if (this.createFormattedArray('') == '') {
    //   // invalid form
    //   this.notifyService.showError(
    //     this.translate.instant(OrdersMessages.invalidForm),
    //     this.translate.instant(OrdersMessages.error)
    //   );
    //   return;
    // }

    var formData = {
      uid: this.id,
      Title: this.timeSlotForm.value.title,
      mondayfrom: this.timeSlotForm.value.mondayfrom,
      mondayTo: this.timeSlotForm.value.mondayTo,
      tuesdayfrom: this.timeSlotForm.value.tuesdayfrom,
      tuesdayTo: this.timeSlotForm.value.tuesdayTo,
      wednesdayfrom: this.timeSlotForm.value.wednesdayfrom,
      wednesdayTo: this.timeSlotForm.value.wednesdayTo,
      thursdayfrom: this.timeSlotForm.value.thursdayfrom,
      thursdayTo: this.timeSlotForm.value.thursdayTo,
      fridayfrom: this.timeSlotForm.value.fridayfrom,
      fridayTo: this.timeSlotForm.value.fridayTo,
      saturdayfrom: this.timeSlotForm.value.saturdayfrom,
      saturdayTo: this.timeSlotForm.value.saturdayTo,
      sundayfrom: this.timeSlotForm.value.sundayfrom,
      sundayTo: this.timeSlotForm.value.sundayTo,
      value: '',
      isstandardpickup: this.isstandardpickup,
      isalternatepickup: this.isalternatepickup,
      isstandarddelivery: this.isstandarddelivery,
      isalternatedelivery: this.isalternatedelivery,

      standarddelivery:
        this.dialogType == 'openingTimesDeliveryStandard'
          ? this.createFormattedArray('standarddelivery')
          : this.branchService.standarddelivery,
      standardpickup:
        this.dialogType == 'openingTimesPickupStandard'
          ? this.createFormattedArray('standardpickup')
          : this.branchService.standardpickup,
      alternatedelivery:
        this.dialogType == 'openingTimesDeliveryAlternative'
          ? this.createFormattedArray('alternatedelivery')
          : this.branchService.alternatedelivery,
      alternatepickup:
        this.dialogType == 'openingTimesPickupAlternative'
          ? this.createFormattedArray('alternatepickup')
          : this.branchService.alternatepickup,
      publicholidaysdelivery:
        this.dialogType == 'openingTimesPublicHolidaysDelivery'
          ? this.createPublicHolidayArray(
              eval(this.publicholidaysdelivery),
              'publicholidaysdelivery'
            )
          : this.publicholidaysdelivery,
      publicholidayspickup:
        this.dialogType == 'openingTimesPublicHolidaysPickup'
          ? this.createPublicHolidayArray(
              eval(this.publicholidayspickup),
              'publicholidayspickup'
            )
          : this.publicholidayspickup,
      dialogType: this.dialogType,
    };

    // return;

    if (this.id) {
      this.updateTimeSlote(formData);
    } else {
      this.addTimeSlote(formData);
    }
  }

  // form validations end

  // ------------------------------------------------------------------------------------------------
  // @ Add
  // ------------------------------------------------------------------------------------------------
  addTimeSlote(data) {
    this.branchService.addTimeSlote(data).subscribe(
      (response: any) => {
        if (response.success == true) {
          this.onNoClick(data,response.data.uid); // store result id
          this.notifyService.showSuccess(
            this.translate.instant(productMessage.add),
            this.translate.instant(OrdersMessages.success)
          );
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
      },
      (err) => {
        //	this.errors = err;
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ Update
  // ------------------------------------------------------------------------------------------------
  updateTimeSlote(data) {

    this.branchService.updateTimeSlote(data).subscribe(
      (response: any) => {
        if (response.success == true) {
          // updated
          this.onNoClick(data,this.id); // store result id
          this.notifyService.showSuccess(
            this.translate.instant(OrdersMessages.update),
            this.translate.instant(OrdersMessages.success)
          );
        } else {
          this.notifyService.showError(
            response.message,
            this.translate.instant(OrdersMessages.error)
          );
        }
      },
      (err) => {
        //  this.errors = err;
      }
    );
  }

  // ------------------------------------------------------------------------------------------------
  // @ Delete
  // ------------------------------------------------------------------------------------------------

  deleteTimeSlote(slotId: number) {
    if (confirm(this.translate.instant(OrdersMessages.confirmDelete))) {
      this.branchService.deleteTimeSlote(slotId).subscribe(
        (response: any) => {
          if (response.success == true) {
            // delete
            this.notifyService.showSuccess(
              this.translate.instant(OrdersMessages.delete),
              this.translate.instant(OrdersMessages.success)
            );
            this.onNoClick();
          } else if (
            response.success == false &&
            response.code ==
              BranchDetailsMessage.responseCode.timeslot_Associated
          ) {
            // Associate
            this.notifyService.showError(
              this.translate.instant(OrdersMessages.timeslotAssociated),
              this.translate.instant(OrdersMessages.error)
            );
          }
        },
        (err) => {
          // this.errors = err;
        }
      );
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }
  }

  public deletePublicHoliday(value: string, id: number) {
    console.log(id);
    if (value == 'openingTimesPublicHolidaysDelivery') {
      this.deleteHolidayArray = this.deleteHolidayArray.filter(
        (item) => item.id !== id
      );
      for (let i = 0; i < this.deleteHolidayArray.length; i++) {
        this.deleteHolidayArray[i].id = i + 1;
      }
    } else {
      this.deleteHolidayPickupArray = this.deleteHolidayPickupArray.filter(
        (item) => item.id !== id
      );
      for (let i = 0; i < this.deleteHolidayPickupArray.length; i++) {
        this.deleteHolidayPickupArray[i].id = i + 1;
      }
    }

    this.notifyService.showSuccess(
      this.translate.instant(OrdersMessages.delete),
      this.translate.instant(OrdersMessages.success)
    );

    var formData = {
      uid: this.id,
      Title: this.timeSlotForm.value.title,
      mondayfrom: this.timeSlotForm.value.mondayfrom,
      mondayTo: this.timeSlotForm.value.mondayTo,
      tuesdayfrom: this.timeSlotForm.value.tuesdayfrom,
      tuesdayTo: this.timeSlotForm.value.tuesdayTo,
      wednesdayfrom: this.timeSlotForm.value.wednesdayfrom,
      wednesdayTo: this.timeSlotForm.value.wednesdayTo,
      thursdayfrom: this.timeSlotForm.value.thursdayfrom,
      thursdayTo: this.timeSlotForm.value.thursdayTo,
      fridayfrom: this.timeSlotForm.value.fridayfrom,
      fridayTo: this.timeSlotForm.value.fridayTo,
      saturdayfrom: this.timeSlotForm.value.saturdayfrom,
      saturdayTo: this.timeSlotForm.value.saturdayTo,
      sundayfrom: this.timeSlotForm.value.sundayfrom,
      sundayTo: this.timeSlotForm.value.sundayTo,
      value: '',
      isstandardpickup: this.isstandardpickup,
      isalternatepickup: this.isalternatepickup,
      isstandarddelivery: this.isstandarddelivery,
      isalternatedelivery: this.isalternatedelivery,

      standarddelivery:
        this.dialogType == 'openingTimesDeliveryStandard'
          ? this.createFormattedArray('standarddelivery')
          : this.branchService.standarddelivery,
      standardpickup:
        this.dialogType == 'openingTimesPickupStandard'
          ? this.createFormattedArray('standardpickup')
          : this.branchService.standardpickup,
      alternatedelivery:
        this.dialogType == 'openingTimesDeliveryAlternative'
          ? this.createFormattedArray('alternatedelivery')
          : this.branchService.alternatedelivery,
      alternatepickup:
        this.dialogType == 'openingTimesPickupAlternative'
          ? this.createFormattedArray('alternatepickup')
          : this.branchService.alternatepickup,
      publicholidaysdelivery:
        this.dialogType == 'openingTimesPublicHolidaysDelivery'
          ? this.createPublicHolidayArray(
              eval(this.deleteHolidayArray),
              'publicholidaysdelivery'
            )
          : this.publicholidaysdelivery,
      publicholidayspickup:
        this.dialogType == 'openingTimesPublicHolidaysPickup'
          ? this.createPublicHolidayArray(
              eval(this.deleteHolidayPickupArray),
              'publicholidayspickup'
            )
          : this.publicholidayspickup,
      dialogType: this.dialogType,
    };

    this.updateTimeSlote(formData);
  }

  createPublicHolidayArray(ArrayData = [], type) {
    if (this.publicHolidayId) {
      // update existing record
      for (var i = 0; i < ArrayData.length; i++) {
        if (this.publicHolidayId == ArrayData[i].id) {
          this.PublicHolidayArr = {
            id: this.publicHolidayId,
            title: this.timeSlotHolidayForm.value.title,
            from: this.timeSlotHolidayForm.value.fromTime,
            to: this.timeSlotHolidayForm.value.toTime,
            date: this.timeSlotHolidayForm.value.date,
            closeDay: this.timeSlotHolidayForm.value.closeDay,
          };

          this.tempArr.push(this.PublicHolidayArr);
        } else {
          this.tempArr.push(ArrayData[i]);
        }
      }
      this.PublicHolidayArr = this.tempArr;
    } else if (ArrayData?.length >= 1) {
      // add new in object
      var index = ArrayData.length + 1;
      var dataUpdate = {
        id: index,
        title: this.timeSlotHolidayForm.value.title,
        from: this.timeSlotHolidayForm.value.fromTime,
        to: this.timeSlotHolidayForm.value.toTime,
        date: this.timeSlotHolidayForm.value.date,
        closeDay: this.timeSlotHolidayForm.value.closeDay,
      };
      ArrayData.push(dataUpdate);
      this.PublicHolidayArr = ArrayData;
    } else {
      // add first record
      this.PublicHolidayArr = [
        {
          id: 1,
          title: this.timeSlotHolidayForm.value.title,
          from: this.timeSlotHolidayForm.value.fromTime,
          to: this.timeSlotHolidayForm.value.toTime,
          date: this.timeSlotHolidayForm.value.date,
          closeDay: this.timeSlotHolidayForm.value.closeDay,
        },
      ];
    }

    if (type == 'publicholidaysdelivery') {
      this.publicholidaysdelivery = JSON.stringify(this.PublicHolidayArr);
      this.branchService.publicholidaysdelivery = this.publicholidaysdelivery;
    } else if (type == 'publicholidayspickup') {
      this.publicholidayspickup = JSON.stringify(this.PublicHolidayArr);
      this.branchService.publicholidayspickup = this.publicholidayspickup;
    }
    return JSON.stringify(this.PublicHolidayArr);
  }

  // ------------------------------------------------------------------------------------------------
  // @ Create formatted array for timeslot calender on mobile app site
  // ------------------------------------------------------------------------------------------------
  createFormattedArray(arrayType) {
    //  true -> close, false -> open
    var mondayClosed = false;
    var tuesdayClosed = false;
    var wednesdayClosed = false;
    var thursdayClosed = false;
    var fridayClosed = false;
    var saturdayClosed = false;
    var sundayClosed = false;

    if (
      !this.timeSlotForm.value.mondayfrom &&
      !this.timeSlotForm.value.mondayTo
    ) {
      var mondayClosed = true;
    }

    if (
      !this.timeSlotForm.value.tuesdayfrom &&
      !this.timeSlotForm.value.tuesdayTo
    ) {
      var tuesdayClosed = true;
    }

    if (
      !this.timeSlotForm.value.wednesdayfrom &&
      !this.timeSlotForm.value.wednesdayTo
    ) {
      var wednesdayClosed = true;
    }

    if (
      !this.timeSlotForm.value.thursdayfrom &&
      !this.timeSlotForm.value.thursdayTo
    ) {
      var thursdayClosed = true;
    }

    if (
      !this.timeSlotForm.value.fridayfrom &&
      !this.timeSlotForm.value.fridayTo
    ) {
      var fridayClosed = true;
    }

    if (
      !this.timeSlotForm.value.saturdayfrom &&
      !this.timeSlotForm.value.saturdayTo
    ) {
      var saturdayClosed = true;
    }

    if (
      !this.timeSlotForm.value.sundayfrom &&
      !this.timeSlotForm.value.sundayTo
    ) {
      var sundayClosed = true;
    }

    if (
      mondayClosed &&
      tuesdayClosed &&
      wednesdayClosed &&
      thursdayClosed &&
      fridayClosed &&
      saturdayClosed &&
      sundayClosed
    ) {
      return ''; // if all true/empty return false
    }
    var weekDataArr = [
      {
        weekday: 1,
        from: this.timeSlotForm.value.mondayfrom,
        to: this.timeSlotForm.value.mondayTo,
        closed: mondayClosed,
        weekday_formatted: 'monday',
      },
      {
        weekday: 2,
        from: this.timeSlotForm.value.tuesdayfrom,
        to: this.timeSlotForm.value.tuesdayTo,
        closed: tuesdayClosed,
        weekday_formatted: 'tuesday',
      },
      {
        weekday: 3,
        from: this.timeSlotForm.value.wednesdayfrom,
        to: this.timeSlotForm.value.wednesdayTo,
        closed: wednesdayClosed,
        weekday_formatted: 'wednesday',
      },
      {
        weekday: 4,
        from: this.timeSlotForm.value.thursdayfrom,
        to: this.timeSlotForm.value.thursdayTo,
        closed: thursdayClosed,
        weekday_formatted: 'thursday',
      },
      {
        weekday: 5,
        from: this.timeSlotForm.value.fridayfrom,
        to: this.timeSlotForm.value.fridayTo,
        closed: fridayClosed,
        weekday_formatted: 'friday',
      },
      {
        weekday: 6,
        from: this.timeSlotForm.value.saturdayfrom,
        to: this.timeSlotForm.value.saturdayTo,
        closed: saturdayClosed,
        weekday_formatted: 'saturday',
      },
      {
        weekday: 7,
        from: this.timeSlotForm.value.sundayfrom,
        to: this.timeSlotForm.value.sundayTo,
        closed: sundayClosed,
        weekday_formatted: 'sunday',
      },
    ];

    var daysHeading = {
      title: this.timeSlotForm.value.title,
      openings: weekDataArr,
    };
    if (arrayType == 'alternatedelivery') {
      this.branchService.alternatedelivery = JSON.stringify(daysHeading);
    } else if (arrayType == 'alternatepickup') {
      this.branchService.alternatepickup = JSON.stringify(daysHeading);
    } else if (arrayType == 'standarddelivery') {
      this.branchService.standarddelivery = JSON.stringify(daysHeading);
    } else if (arrayType == 'standardpickup') {
      this.branchService.standardpickup = JSON.stringify(daysHeading);
    }

    return JSON.stringify(daysHeading);
  }
}
