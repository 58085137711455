import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JwtService } from '../services';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../services/loader.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService, private loaderService: LoaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // const headersConfig = {
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin':'*'
    // };

    const headersConfig = {
      'enctype': 'multipart/form-data;',
      'Accept': 'plain/text',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Access-Control-Allow-Headers': 'Authorization, Origin, Content-Type, X-CSRF-Token',
      // 'Content-Type': 'application/json'
    };



    const token = this.jwtService.getToken();
    if (token) {
      headersConfig['Authorization'] = 'Bearer '+`${token}`;
    }

    const request = req.clone({ setHeaders: headersConfig });
    // return next.handle(request);

    if (req.body instanceof FormData) {
      req.clone({setHeaders:{'ContentType' : 'multipart/form-data'}});
    }
    else{
       req.clone({setHeaders:{'Content-Type': 'application/json'}});
    }
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // this.onEnd();
      }
    },
      (err: any) => {
        // this.onEnd();
    }));

  }

  onEnd(): void {
    this.hideLoader();
  }
   showLoader(): void {
    this.loaderService.show();
  }
   hideLoader(): void {
    this.loaderService.hide();
  }






}
