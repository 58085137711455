import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient
  ) {}
  formatErrors(error: HttpErrorResponse) {    
    if (error.error instanceof ErrorEvent) {      
      // A client-side or network error occurred. Handle it accordingly.      
      return throwError(error);    
    }

    // The backend returned an unsuccessful response code.    
    // The response body may contain clues as to what went wrong.    
    // Return an observable with a user-facing error message.    
    const isErrorMessageString = typeof error.error === 'string';    
    return throwError({      
      origin: 'server',      
      status: error.status,      
      error: {        
        message: isErrorMessageString          
        ? error.error          
        : error.error?.message || error.message,        
        data: isErrorMessageString ? null : error.error?.data,      
      },    
    });  
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiURL}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body): Observable<any> {
    return this.http.put(
      `${environment.apiURL}${path}`,body
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body): Observable<any> {
    return this.http.post(
      `${environment.apiURL}${path}`,body
    ).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.apiURL}${path}`
    ).pipe(catchError(this.formatErrors));
  }
}
