import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Branch } from '../../models/branch';
import { BranchService } from '../../services/branch.service';

@Component({
  selector: 'app-branch-page',
  templateUrl: './branch-page.component.html',
  styleUrls: ['./branch-page.component.scss']
})
export class BranchPageComponent implements OnInit {
  public branchTableData$: Observable<Branch[]>

  constructor() {
    
  }

  ngOnInit(): void {
    
  }

}
